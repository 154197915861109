@import "abstracts/vars";
@import "abstracts/mixins";

.tabs.tabs-scrollable {
  .tabs-heading {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
  }

  .tabs-container {
    margin: 0;
  }

  .prev {
    background: #e4e4e4 url("../static-assets/images/arrow-left.png") center center no-repeat;
    left: 0;
  }

  .next {
    background: #e4e4e4 url("../static-assets/images/arrow-right.png") center center no-repeat;
    right: 0;
  }

  &.tabs-bottom {
    .wrapper {
      margin-top: 2px;
      padding-bottom: 6px;
    }

    .next {
      top: 1px;
    }

    .prev {
      top: 1px;
    }

    .tabs-heading {
      margin-top: -4px;
    }
  }
}
