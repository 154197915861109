/* stylelint-disable no-invalid-position-at-import-rule */
@import "abstracts/mixins";
@import "abstracts/vars";
@import "libs/fontawesome/font-awesome-mix-var";

// TODO: Fix this code-smell
.link-list.usps,
.link-list.footer-navigation,
.link-list.footer-links-navigation,
.link-list.service-navigation {
  background: $link-list-bg;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include border-basic(bottom, $link-list-header-border);
  }

  > .component-content {
    ul {
      background: $link-list-items-bg;
    }

    li {
      background: $link-list-item-bg;
      display: block;
      font-size: $font-normal;
    }
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.link-list:not(.usps) {
  @import "base/links/link-button";

  /* TODO: Remove SXA styling */
  @import "variants/link-list/sector-navigation";
  @import "variants/link-list/service-navigation";
  @import "variants/link-list/social-navigation";
}

@import "styles/link-list/usps";
@import "styles/link-list/list-vertical";
