@import "variables";


/**
Usage:
<div class="tooltip">
  <span className="tooltip_text">
    Some text
  </span>
</div>
*/


.tooltip {
  position: relative;
}

.tooltip .tooltip_text {
  background-color: $white;
  border-radius: 2px;
  bottom: 125%;
  box-shadow: $box-shadow-color;
  font-weight: 700;
  left: 50%;
  margin-left: -60px;
  opacity: 0%;
  padding: 0.5rem 0;
  position: absolute;
  text-align: center;
  transition: opacity 0.3s;
  visibility: hidden;
  width: 120px;
  z-index: 10;
}

.tooltip .tooltip_text::after {
  background-color: $white;
  content: " ";
  display: block;
  height: 10px;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  position: absolute;
  top: 100%;
  transform: rotate(45deg);
  width: 10px;
  z-index: -1;
}

.tooltip:hover .tooltip_text {
  opacity: 100%;
  visibility: visible;
}
