span.field-title {
  &.tag-text {
    border-bottom: 1px solid transparent;
    display: block;
    float: left;
    padding-left: 20px;
    position: relative;

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }

    &::before {
      content: $fa-var-tag;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      @include fa-icon;
    }
  }
}
