/* stylelint-disable */
// Embedded icons from Open Iconic.
// Released under MIT and copyright 2014 Waybury.
// https://useiconic.com/open


// Checkboxes and radios
//
// Base class takes care of all the key behavioral aspects.

.custom-control {
  color-adjust: exact; // Keep themed appearance for print
  display: block;
  min-height: $font-size-base * $line-height-base;
  padding-left: $custom-control-gutter + $custom-control-indicator-size;
  position: relative;
  z-index: 1;

  &[readonly] {
    cursor: default;
  }
}

.custom-control-inline {
  display: inline-flex;
  margin-right: $custom-control-spacer-x;
}

.custom-control-input {
  height: divide($font-size-base * $line-height-base + $custom-control-indicator-size, 2);
  left: 0;
  opacity: 0%;
  position: absolute;
  width: $custom-control-indicator-size;
  z-index: -1; // Put the input behind the label so it doesn't overlay text

  &:checked ~ .custom-control-label::before {
    border-color: $custom-control-indicator-checked-border-color;
    color: $custom-control-indicator-checked-color;
    @include gradient-bg($custom-control-indicator-checked-bg);
    @include box-shadow($custom-control-indicator-checked-box-shadow);
  }

  &:focus ~ .custom-control-label::before {
    // the mixin is not used here to make sure there is feedback
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $custom-control-indicator-focus-box-shadow;
    } @else {
      box-shadow: $custom-control-indicator-focus-box-shadow;
    }
  }

  &:focus:not(:checked) ~ .custom-control-label::before {
    border-color: $custom-control-indicator-focus-border-color;
  }

  &:not(:disabled):active ~ .custom-control-label::before {
    background-color: $custom-control-indicator-active-bg;
    border-color: $custom-control-indicator-active-border-color;
    color: $custom-control-indicator-active-color;
    @include box-shadow($custom-control-indicator-active-box-shadow);
  }

  // Use [disabled] and :disabled to work around https://github.com/twbs/bootstrap/issues/28247
  &[disabled],
  &:disabled {
    ~ .custom-control-label {
      color: $custom-control-label-disabled-color;

      &::before {
        background-color: $custom-control-indicator-disabled-bg;
      }
    }
  }
}

// Custom control indicators
//
// Build the custom controls out of pseudo-elements.

.custom-control-label {
  color: $custom-control-label-color;
  cursor: $custom-control-cursor;
  margin-bottom: 0;
  position: relative;
  vertical-align: top;

  // Background-color and (when enabled) gradient
  &::before {
    background-color: $custom-control-indicator-bg;
    border: $custom-control-indicator-border-color solid $custom-control-indicator-border-width;
    content: "";
    display: block;
    height: $custom-control-indicator-size;
    left: -($custom-control-gutter + $custom-control-indicator-size);
    pointer-events: none;
    position: absolute;
    top: divide($font-size-base * $line-height-base - $custom-control-indicator-size, 2);
    width: $custom-control-indicator-size;
    @include box-shadow($custom-control-indicator-box-shadow);
  }

  // Foreground (icon)
  &::after {
    background: slash-list(50%, $custom-control-indicator-bg-size) no-repeat;
    content: "";
    display: block;
    height: $custom-control-indicator-size;
    left: -($custom-control-gutter + $custom-control-indicator-size);
    position: absolute;
    top: divide($font-size-base * $line-height-base - $custom-control-indicator-size, 2);
    width: $custom-control-indicator-size;
  }
}


// Checkboxes
//
// Tweak just a few things for checkboxes.

.custom-checkbox {
  appearance: none;
  background-color: transparent;
  border: 1px solid $gray-200;
  border-radius: 2px;
  color: currentcolor;
  display: inline-grid;
  font: inherit;
  height: 0.875rem;
  margin: 0;
  place-content: center;
  width: 0.875rem;


  &::before {
    background-color: $white;
    clip-path: polygon(0 60%, 35% 85%, 100% 30%, 100% 10%, 35% 65%, 0 40%);
    content: "";
    height: 0.75em;
    transform: scale(0);
    transform-origin: bottom left;
    transition: 50ms transform ease-in-out;
    width: 0.75em;
  }

  &-lg {
    height: 1rem;
    width: 1rem;
  }

  &:checked {
    background-color: $primary;

    &::before {
      transform: scale(1);
    }
  }

  &--themed {
    color: transprent;

    &:checked {
      background-color: var(--theme-color-2);
      border-color: var(--theme-color-2);

      &::before {
        transform: scale(0);
      }
    }
  }


  .custom-control-label::before {
    @include border-radius($custom-checkbox-indicator-border-radius);
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-image: escape-svg($custom-checkbox-indicator-icon-checked);
    }
  }

  .custom-control-input:indeterminate ~ .custom-control-label {
    &::before {
      border-color: $custom-checkbox-indicator-indeterminate-border-color;
      @include gradient-bg($custom-checkbox-indicator-indeterminate-bg);
      @include box-shadow($custom-checkbox-indicator-indeterminate-box-shadow);
    }

    &::after {
      background-image: escape-svg($custom-checkbox-indicator-icon-indeterminate);
    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      @include gradient-bg($custom-control-indicator-checked-disabled-bg);
    }

    &:indeterminate ~ .custom-control-label::before {
      @include gradient-bg($custom-control-indicator-checked-disabled-bg);
    }
  }
}

// Radios
//
// Tweak just a few things for radios.

.custom-radio {
  .custom-control-label::before {
    // stylelint-disable-next-line property-disallowed-list
    border-radius: $custom-radio-indicator-border-radius;
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-image: escape-svg($custom-radio-indicator-icon-checked);
    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      @include gradient-bg($custom-control-indicator-checked-disabled-bg);
    }
  }
}


// switches
//
// Tweak a few things for switches

.custom-switch {
  padding-left: $custom-switch-width + $custom-control-gutter;

  .custom-control-label {
    &::before {
      // stylelint-disable-next-line property-disallowed-list
      border-radius: $custom-switch-indicator-border-radius;
      left: -($custom-switch-width + $custom-control-gutter);
      pointer-events: all;
      width: $custom-switch-width;
    }

    &::after {
      background-color: $custom-control-indicator-border-color;
      // stylelint-disable-next-line property-disallowed-list
      border-radius: $custom-switch-indicator-border-radius;
      height: $custom-switch-indicator-size;
      left: add(-($custom-switch-width + $custom-control-gutter), $custom-control-indicator-border-width * 2);
      top: add(divide($font-size-base * $line-height-base - $custom-control-indicator-size, 2), $custom-control-indicator-border-width * 2);
      width: $custom-switch-indicator-size;
      @include transition(transform 0.15s ease-in-out, $custom-forms-transition);
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-color: $custom-control-indicator-bg;
      transform: translateX($custom-switch-width - $custom-control-indicator-size);
    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      @include gradient-bg($custom-control-indicator-checked-disabled-bg);
    }
  }
}


// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.
//

.custom-select {
  appearance: none;
  background-color: $custom-select-background-color;
  background-image: url(data:image/svg+xml;carset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20448%20512%22%3E%3Cpath%20d%3D%22M207.029%20381.476L12.686%20187.132c-9.373-9.373-9.373-24.569%200-33.941l22.667-22.667c9.357-9.357%2024.522-9.375%2033.901-.04L224%20284.505l154.745-154.021c9.379-9.335%2024.544-9.317%2033.901.04l22.667%2022.667c9.373%209.373%209.373%2024.569%200%2033.941L240.971%20381.476c-9.373%209.372-24.569%209.372-33.942%200z%22%2F%3E%3C%2Fsvg%3E%0A);
  background-position: right 0.7em top 50%, 0 0;
  background-repeat: no-repeat, repeat;
  background-size: 0.65em auto, 100%;
  border: $custom-select-border-width solid $custom-select-border-color;
  color: $custom-select-color;
  display: inline-block;
  font-family: $custom-select-font-family;
  font-weight: $custom-select-font-weight;
  height: $custom-select-height;
  line-height: $custom-select-line-height;
  padding: $custom-select-padding-y ($custom-select-padding-x + $custom-select-indicator-padding) $custom-select-padding-y $custom-select-padding-x;
  vertical-align: middle;
  width: 100%;
  @include font-size($custom-select-font-size);
  @include border-radius($custom-select-border-radius, 0);
  @include box-shadow($custom-select-box-shadow);

  &:focus {
    border-color: $custom-select-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      @include box-shadow($custom-select-box-shadow, $custom-select-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $custom-select-focus-box-shadow;
    }

    &::-ms-value {
      background-color: $input-bg;
      // For visual consistency with other platforms/browsers,
      // suppress the default white text on blue background highlight given to
      // the selected option text when the (still closed) <select> receives focus
      // in IE and (under certain conditions) Edge.
      // See https://github.com/twbs/bootstrap/issues/19398.
      color: $input-color;
    }
  }

  &[multiple],
  &[size]:not([size="1"]) {
    background-image: none;
    height: auto;
    padding-right: $custom-select-padding-x;
  }

  &:disabled {
    background-color: $custom-select-disabled-bg;
    color: $custom-select-disabled-color;
  }

  // Hides the default caret in IE11
  &::-ms-expand {
    display: none;
  }

  // Remove outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $custom-select-color;
  }
}

.custom-select-sm {
  height: $custom-select-height-sm;
  padding-bottom: $custom-select-padding-y-sm;
  padding-left: $custom-select-padding-x-sm;
  padding-top: $custom-select-padding-y-sm;
  @include font-size($custom-select-font-size-sm);
}

.custom-select-lg {
  font-weight: 500;
  height: $custom-select-height-lg;
  padding-bottom: $custom-select-padding-y-lg;
  padding-left: $custom-select-padding-x-lg;
  padding-top: $custom-select-padding-y-lg;
  @include font-size($custom-select-font-size-lg);
}



// File
//
// Custom file input.

.custom-file {
  display: inline-block;
  height: $custom-file-height;
  margin-bottom: 0;
  position: relative;
  width: 100%;
}

.custom-file-input {
  height: $custom-file-height;
  margin: 0;
  opacity: 0%;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 2;

  &:focus ~ .custom-file-label {
    border-color: $custom-file-focus-border-color;
    box-shadow: $custom-file-focus-box-shadow;
  }

  // Use [disabled] and :disabled to work around https://github.com/twbs/bootstrap/issues/28247
  &[disabled] ~ .custom-file-label,
  &:disabled ~ .custom-file-label {
    background-color: $custom-file-disabled-bg;
  }

  @each $lang, $value in $custom-file-text {
    &:lang(#{$lang}) ~ .custom-file-label::after {
      content: $value;
    }
  }

  ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse);
  }
}

.custom-file-label {
  background-color: $custom-file-bg;
  border: $custom-file-border-width solid $custom-file-border-color;
  color: $custom-file-color;
  font-family: $custom-file-font-family;
  font-weight: $custom-file-font-weight;
  height: $custom-file-height;
  left: 0;
  line-height: $custom-file-line-height;
  overflow: hidden;
  padding: $custom-file-padding-y $custom-file-padding-x;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  @include border-radius($custom-file-border-radius);
  @include box-shadow($custom-file-box-shadow);

  &::after {
    border-left: inherit;
    bottom: 0;
    color: $custom-file-button-color;
    content: "Browse";
    display: block;
    height: $custom-file-height-inner;
    line-height: $custom-file-line-height;
    padding: $custom-file-padding-y $custom-file-padding-x;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    @include gradient-bg($custom-file-button-bg);
    @include border-radius(0 $custom-file-border-radius $custom-file-border-radius 0);
  }
}

// Range
//
// Style range inputs the same across browsers. Vendor-specific rules for pseudo
// elements cannot be mixed. As such, there are no shared styles for focus or
// active states on prefixed selectors.

.custom-range {
  appearance: none;
  background-color: transparent;
  height: add($custom-range-thumb-height, $custom-range-thumb-focus-box-shadow-width * 2);
  padding: 0; // Need to reset padding
  width: 100%;

  &:focus {
    outline: 0;

    // Pseudo-elements must be split across multiple rulesets to have an effect.
    // No box-shadow() mixin for focus accessibility.
    &::-webkit-slider-thumb { box-shadow: $custom-range-thumb-focus-box-shadow; }
    &::-moz-range-thumb { box-shadow: $custom-range-thumb-focus-box-shadow; }
    &::-ms-thumb { box-shadow: $custom-range-thumb-focus-box-shadow; }
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    border: $custom-range-thumb-border;
    height: $custom-range-thumb-height;
    margin-top: divide($custom-range-track-height - $custom-range-thumb-height, 2); // Webkit specific
    width: $custom-range-thumb-width;
    @include gradient-bg($custom-range-thumb-bg);
    @include border-radius($custom-range-thumb-border-radius);
    @include box-shadow($custom-range-thumb-box-shadow);
    @include transition($custom-forms-transition);

    &:active {
      @include gradient-bg($custom-range-thumb-active-bg);
    }
  }

  &::-webkit-slider-runnable-track {
    background-color: $custom-range-track-bg;
    border-color: transparent;
    color: transparent; // Why?
    cursor: $custom-range-track-cursor;
    height: $custom-range-track-height;
    width: $custom-range-track-width;
    @include border-radius($custom-range-track-border-radius);
    @include box-shadow($custom-range-track-box-shadow);
  }

  &::-moz-range-thumb {
    appearance: none;
    border: $custom-range-thumb-border;
    height: $custom-range-thumb-height;
    width: $custom-range-thumb-width;
    @include gradient-bg($custom-range-thumb-bg);
    @include border-radius($custom-range-thumb-border-radius);
    @include box-shadow($custom-range-thumb-box-shadow);
    @include transition($custom-forms-transition);

    &:active {
      @include gradient-bg($custom-range-thumb-active-bg);
    }
  }

  &::-moz-range-track {
    background-color: $custom-range-track-bg;
    border-color: transparent; // Firefox specific?
    color: transparent;
    cursor: $custom-range-track-cursor;
    height: $custom-range-track-height;
    width: $custom-range-track-width;
    @include border-radius($custom-range-track-border-radius);
    @include box-shadow($custom-range-track-box-shadow);
  }

  &::-ms-thumb {
    appearance: none;
    border: $custom-range-thumb-border;
    height: $custom-range-thumb-height;
    margin-left: $custom-range-thumb-focus-box-shadow-width;  // Workaround that overflowed box-shadow is hidden.
    margin-right: $custom-range-thumb-focus-box-shadow-width; // Workaround that overflowed box-shadow is hidden.
    margin-top: 0; // Edge specific
    width: $custom-range-thumb-width;
    @include gradient-bg($custom-range-thumb-bg);
    @include border-radius($custom-range-thumb-border-radius);
    @include box-shadow($custom-range-thumb-box-shadow);
    @include transition($custom-forms-transition);

    &:active {
      @include gradient-bg($custom-range-thumb-active-bg);
    }
  }

  &::-ms-track {
    background-color: transparent;
    border-color: transparent;
    border-width: divide($custom-range-thumb-height, 2);
    color: transparent;
    cursor: $custom-range-track-cursor;
    height: $custom-range-track-height;
    width: $custom-range-track-width;
    @include box-shadow($custom-range-track-box-shadow);
  }

  &::-ms-fill-lower {
    background-color: $custom-range-track-bg;
    @include border-radius($custom-range-track-border-radius);
  }

  &::-ms-fill-upper {
    background-color: $custom-range-track-bg;
    margin-right: 15px; // arbitrary?
    @include border-radius($custom-range-track-border-radius);
  }

  &:disabled {
    &::-webkit-slider-thumb {
      background-color: $custom-range-thumb-disabled-bg;
    }

    &::-webkit-slider-runnable-track {
      cursor: default;
    }

    &::-moz-range-thumb {
      background-color: $custom-range-thumb-disabled-bg;
    }

    &::-moz-range-track {
      cursor: default;
    }

    &::-ms-thumb {
      background-color: $custom-range-thumb-disabled-bg;
    }
  }
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  @include transition($custom-forms-transition);
}
