@import "abstracts/vars";
@import "abstracts/mixins";
@import "libs/fontawesome/font-awesome-mix-var";

.variant-selector {
  &.component {
    margin: 30px 0 0;
  }

  .component-content {
    >div {
      cursor: pointer;
      display: inline-block;
      height: 30px;
      margin: 2px;
      width: 30px;
    }
  }

  .variant-option {
    @include fa-icon;

    &::before {
      content: $fa-var-th;
      display: block;
      font-size: 30px;
      height: 100%;
      text-align: center;
      width: 100%;
    }

    &.active-variant {
      color: $serach-selector-variant-color-active;
    }

    &.horizontal {
      &::before {
        content: $fa-var-th-large;
      }

      &.active-variant {
        color: $serach-selector-variant-color-active;
      }
    }

    &.vertical {
      &::before {
        content: $fa-var-bars;
      }

      &.active-variant {
        color: $serach-selector-variant-color-active;
      }
    }
  }
}
