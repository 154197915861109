&.file-list-files {
  .component-content {
    >ul {
      >li,
      li > .scLooseFrameZone {
        align-items: center;
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  li {
    margin: 0;
    padding-bottom: $default-padding;
    padding-top: $default-padding;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .field-filetypeicon {
    min-width: 23px;
    order: 1;
    width: 3%;

    span {
      &::before {
        font-size: 20px;
      }
    }
  }

  .field-title {
    order: 1;
    padding: 0;
    width: 50%;
  }

  .field-updated {
    order: 1;
    text-align: right;
    width: 45%;
  }

  .field-linktext {
    font-weight: 600;
    order: 1;
    text-align: center;
  }
}
