@import "abstracts/vars";
@import "abstracts/mixins";

.playlist.playlist-horizontal {
  .playlist-items {
    display: inline-flex;

    .playlist-item {
      min-width: 150px;

      .playlist-section {
        display: block;

        > div {
          width: 100%;
        }
      }

      .field-moviecaption {
        text-align: center;
      }
    }
  }
}
