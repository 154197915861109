/* stylelint-disable */


$sprite-flag-image: "../static-assets/images/sprite-flag.png" !default;

$flags-de-name: 'flags-de';
$flags-de: (0px, 1400px, 50px, -1400px, 100%, 15px, 20px, 6240px, $sprite-flag-image, 'flags-de', );

$flags-nl-name: 'flags-nl';
$flags-nl: (0px, 4125px, 50px, -4125px, 100%, 15px, 20px, 6240px, $sprite-flag-image, 'flags-nl', );

$flags-us-name: 'flags-us';
$flags-us: (0px, 5800px, 50px, -5800px, 100%, 15px, 20px, 6240px, $sprite-flag-image, 'flags-us', );

$spritesheet-width: 20px;
$spritesheet-height: 6240px;
$spritesheet-image: $sprite-flag-image;
$spritesheet-sprites: ($flags-de, $flags-nl, $flags-us);
$spritesheet: (20px, 6240px, $sprite-flag-image, $spritesheet-sprites, );


@mixin sprite-width($sprite) {
    width: nth($sprite, 5);
}
@mixin sprite-height($sprite) {
    height: nth($sprite, 6);
}
@mixin sprite-position($sprite) {
    $sprite-offset-x: nth($sprite, 3);
    $sprite-offset-y: nth($sprite, 4);
    background-position: $sprite-offset-x $sprite-offset-y;
}
@mixin sprite-image($sprite) {
    $sprite-image: nth($sprite, 9);
    background-image: url(#{$sprite-image});
}
@mixin sprite($sprite) {
    @include sprite-image($sprite);
    @include sprite-position($sprite);
    @include sprite-width($sprite);
    @include sprite-height($sprite);
}

@mixin sprites($sprites) {
    @each $sprite in $sprites {
        $sprite-name: nth($sprite, 10);
        .#{$sprite-name} {
            @include sprite($sprite);
        }
    }
}
