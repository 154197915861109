@import "sxa/abstracts/vars";

.radius-filter {
  @import "../reset/inputs";

  .radius-button {
    &.selected {
      background: $bg-basic-color;
      color: $search-filter-radius-active;
    }
  }

  ul {
    li {
      border-color: $search-filter-radius-border;
      border-style: solid;
      border-width: 1px 1px 1px 0;
      cursor: pointer;
      display: block;
      float: left;
      padding: 5px;

      &:first-child {
        border-width: 1px;
      }
    }

    height: 30px;
  }

  .bottom-remove-filter {
    margin-bottom: 5px;
  }
}
