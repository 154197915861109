.flip.flip-slide-top {
  .Side0,
  .Side1 {
    box-sizing: border-box;
    transition-duration: 1s, 1s, 1s;
    transition-property: height, visibility, padding-top;
    transition-timing-function: linear, linear, linear;
  }

  .Side1 {
    height: 0;
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;
    transform: rotateY(0deg);
    visibility: hidden;

    .slide-heading {
      margin-top: 16px;
    }
  }

  &.active {
    .Side0 {
      transform: rotateY(0deg);
    }

    .Side1 {
      height: 100%;
      padding-bottom: 10px;
      padding-top: 0;
      transform: rotateY(0deg);
      visibility: visible;
    }
  }
}
