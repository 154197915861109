@import "abstracts/mixins";
@import "abstracts/vars";
@import "libs/fontawesome/font-awesome-mix-var";

.carousel {
  @include border-basic;

  background: $carousel-bg;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  text-align: center;

  .wrapper {
    padding-bottom: 30px;
  }

  .slides {
    position: relative;

    &::after {
      clear: both;
      content: "";
      display: block;
    }
  }

  .nav {
    margin-top: 10px;

    .nav-items {
      cursor: pointer;
      display: inline-block;
      height: 32px;
      margin: 0 15px;
      min-width: 20px;
      position: relative;
      vertical-align: middle;

      &::before,
      &::after {
        background-image: url("#{$static-assets-path}/images/player.png");
        bottom: 0;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        width: 9px;
      }

      &::before {
        background-position: -154px -152px;
        left: -9px;
      }

      &::after {
        background-position: -225px -152px;
        right: -9px;
      }

      //For Bullets styles start
      .sxa-bullets,
      .sxa-numbers {
        box-sizing: border-box;
        float: left;
        height: 32px;
        position: relative;
        width: 20px;
      }

      .sxa-bullets {
        &::before {
          background-image: url("#{$static-assets-path}/images/player.png");
          background-position: 0 -152px;
          content: "";
          display: block;
          height: 32px;
          left: -10px;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 20px;
        }

        &.active::before {
          background-position: -75px -152px;
        }
      }

      //For Bullets styles end
      //For Numbers styles start
      .sxa-numbers {
        /* IE9 SVG, needs conditional override of 'filter' to 'none' */
        background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjMlIiBzdG9wLWNvbG9yPSIjZWZlZmVmIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTYlIiBzdG9wLWNvbG9yPSIjZmJmYmZiIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMjIlIiBzdG9wLWNvbG9yPSIjZmVmZWZlIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMzQlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNDElIiBzdG9wLWNvbG9yPSIjZmRmZGZkIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjZjdmN2Y3IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNjYlIiBzdG9wLWNvbG9yPSIjZThlOGU4IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNzglIiBzdG9wLWNvbG9yPSIjZDVkNWQ1IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iODQlIiBzdG9wLWNvbG9yPSIjY2VjZWNlIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iODglIiBzdG9wLWNvbG9yPSIjMGQwZjExIiBzdG9wLW9wYWNpdHk9IjAuNDQiLz4KICAgIDxzdG9wIG9mZnNldD0iOTQlIiBzdG9wLWNvbG9yPSIjMGQwZjExIiBzdG9wLW9wYWNpdHk9IjAuMyIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMGQwZjExIiBzdG9wLW9wYWNpdHk9IjAuMTIiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==");
        background: linear-gradient(to top, rgba(238, 238, 238, 100%) 0%, rgba(239, 239, 239, 100%) 3%, rgba(251, 251, 251, 100%) 16%, rgba(254, 254, 254, 100%) 22%, rgba(255, 255, 255, 100%) 34%, rgba(253, 253, 253, 100%) 41%, rgba(247, 247, 247, 100%) 50%, rgba(232, 232, 232, 100%) 66%, rgba(213, 213, 213, 100%) 78%, rgba(206, 206, 206, 100%) 84%, rgba(13, 15, 17, 44%) 88%, rgba(13, 15, 17, 30%) 94%, rgba(13, 15, 17, 12%) 100%);

        /* FF3.6-15 */
        background: linear-gradient(to top, rgba(238, 238, 238, 100%) 0%, rgba(239, 239, 239, 100%) 3%, rgba(251, 251, 251, 100%) 16%, rgba(254, 254, 254, 100%) 22%, rgba(255, 255, 255, 100%) 34%, rgba(253, 253, 253, 100%) 41%, rgba(247, 247, 247, 100%) 50%, rgba(232, 232, 232, 100%) 66%, rgba(213, 213, 213, 100%) 78%, rgba(206, 206, 206, 100%) 84%, rgba(13, 15, 17, 44%) 88%, rgba(13, 15, 17, 30%) 94%, rgba(13, 15, 17, 12%) 100%);

        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(238, 238, 238, 100%) 0%, rgba(239, 239, 239, 100%) 3%, rgba(251, 251, 251, 100%) 16%, rgba(254, 254, 254, 100%) 22%, rgba(255, 255, 255, 100%) 34%, rgba(253, 253, 253, 100%) 41%, rgba(247, 247, 247, 100%) 50%, rgba(232, 232, 232, 100%) 66%, rgba(213, 213, 213, 100%) 78%, rgba(206, 206, 206, 100%) 84%, rgba(13, 15, 17, 44%) 88%, rgba(13, 15, 17, 30%) 94%, rgba(13, 15, 17, 12%) 100%);
        padding-top: 3px;

        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

        &:hover {
          color: $carousel-nav-active;
        }

        &.active {
          font-weight: bold;
        }
      }

      //For Bullets styles end
    }

    .prev-text,
    .next-text {
      color: $carousel-nav;
      display: inline-block;
      font-size: 0;
      position: relative;
      text-decoration: none;
      vertical-align: middle;
    }

    a.next-text {
      &:hover,
      &:active {
        color: $carousel-nav-active;
        text-decoration: none;
      }

      &::after {
        content: $fa-var-chevron-right;
        display: block;
        font-size: 20px;

        @include fa-icon;
      }
    }

    a.prev-text {
      &:hover,
      &:active {
        text-decoration: none;
      }

      &::after {
        @include fa-icon;

        content: $fa-var-chevron-left;
        display: block;
        font-size: 20px;
      }
    }
  }

  &.image-full-size {
    .carousel-body {
      height: auto;
      opacity: 80%;
      top: 2%;
    }

    .carousel-link {
      bottom: 2%;
      left: auto;
      opacity: 80%;
      right: 1%;
    }
  }

  @import "styles/carousel/image-full-size";
  @import "styles/carousel/slide-hero";
}

@media (max-width: 768px) {
  .carousel {
    padding: 0;
  }
}
