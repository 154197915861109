@import "abstracts/mixins";
@import "abstracts/vars";
@import "libs/fontawesome/font-awesome-mix-var";

.on-page-editor {
  .gallery-inner img {
    max-width: 100%;
  }
}

.gallery {
  .galleria-container {
    background: $galleria-container-bg;
  }

  #galleria-loader {
    height: 1px !important;
  }

  .galleria-thumbnails .galleria-image img {
    border: none;
  }

  .galleria-counter {
    color: $gallery-counter-color;

    @include opensans-font-stack;
  }

  .galleria-loader {
    left: 50%;
    top: 50%;
  }

  .galleria-info-text {
    background-color: $gallery-info-bg;
    border: 1px solid $gallery-info-border;
  }

  .galleria-info-title {
    color: $gallery-info-text;
    font-size: 12px;
    font-weight: bold;

    @include opensans-font-stack;
  }

  .galleria-info-description {
    color: $gallery-info-text;
    font-size: 12px;
    font-style: italic;

    @include opensans-font-stack;
  }

  .galleria-info-close {
    height: 12px;
    width: 13px;

    &::before {
      content: $fa-var-close;
      display: block;
      width: 100%;

      @include fa-icon;
    }
  }

  .galleria-info-link {
    background: none;
    color: $gallery-nav;
    font-size: 18px;

    @include fa-icon;

    &::before {
      content: $fa-var-info-circle;
    }
  }

  .galleria-image-nav {
    height: 30px;
    margin-top: 0;
    transform: translateY(-50%);
    z-index: 999;
  }

  .galleria-image-nav-left,
  .galleria-image-nav-right {
    color: $gallery-nav;
    font-size: 28px;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;

    @include fa-icon;
  }

  .galleria-image-nav-left {
    &::before {
      content: $fa-var-chevron-left;
    }

    &:hover {
      color: $gallery-nav-active;
    }
  }

  .galleria-image-nav-right {
    &::before {
      content: $fa-var-chevron-right;
    }

    &:hover {
      color: $gallery-nav-active;
    }
  }

  .galleria-thumb-nav-left,
  .galleria-thumb-nav-right {
    height: 7px;
    top: 15px;
    width: 5px;
  }

  .galleria-thumbnails {
    margin: 0 auto;
  }

  .galleria-thumb-nav-right {
    @include fa-icon;

    right: 10px;

    &::before {
      color: $gallery-nav;
      content: $fa-var-chevron-right;
      display: block;
      font-size: $font-normal;
    }
  }

  .galleria-thumb-nav-left {
    @include fa-icon;

    left: 10px;
    right: auto;

    &::before {
      color: $gallery-nav;
      content: $fa-var-chevron-left;
      display: block;
      font-size: $font-normal;
    }
  }
}

@import "styles/galleria/no-margin";
