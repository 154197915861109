@import "variables";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "libs/fontawesome/font-awesome-mix-var";
@import "utilities/theme-colors";


%btn {
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25;
  padding: 1rem;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  vertical-align: middle;
}

%btn-primary {
  @include button-variant($primary, $primary);
}

%btn-outline-primary {
  @include button-outline-variant($primary);
}


.privacy-warning {
  background-color: $white;
  border-radius: 8px;
  border-top: 1px solid $gray-200;
  bottom: 56px;
  box-shadow: 20px 12px 34px rgba(0, 0, 0, 30%);
  color: $gray-900;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  font-size: 1rem;
  height: auto;
  left: 50%;
  margin: 0;
  max-width: 820px;
  padding: 2rem;
  position: fixed;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1000;

  @include media-breakpoint-down(md) {
    padding: 1.5rem;
    width: calc(100vw - $grid-gutter-width);
  }


  .close {
    display: inline-block;
    margin-right: 1rem;
    order: 3;
    vertical-align: middle;

    a {
      color: transparent !important;
      font-size: 1px;

      &::after {
        @extend %btn;
        @extend %btn-outline-primary;

        color: $primary !important;
        content: "Weigeren";
        font-family: $font-family-sans-serif !important;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }

    &:hover a::after {
      color: $white !important;
    }
  }

  .info {
    color: $gray-500;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 1rem;
    order: 1;

    &::before {
      color: $gray-900;
      content: "Cookies";
      display: block;
      font-family: $font-family-serif;
      font-size: $h1-font-size;
      font-weight: 900;
      line-height: 1;
      margin-bottom: 0.5rem;
      text-align: left;
    }
  }

  .submit {
    display: inline-block;
    order: 2;
    vertical-align: middle;

    a {
      @extend %btn;
      @extend %btn-primary;

      color: $white !important;
    }
  }
}
