/* stylelint-disable no-descending-specificity */
@import "abstracts/mixins";
@import "abstracts/vars";
@import "libs/fontawesome/font-awesome-mix-var";
@import "utilities/typography";

.link {
  @import "base/reset/links";

  a {
    background: $link-bg;
    border-bottom-color: $link-border;
    color: $link-text-color;

    &:hover {
      color: $link-text-color-active;
    }
  }

  &.cta-primary a,
  &.cta-secondary-1 a,
  &.cta-secondary-2 a {
    border: 2px solid transparent;
    border-radius: 2px;
    display: inline-block;
    font-weight: bold;
    line-height: 1.43;
    padding: $default-inputs-padding;
    text-decoration: none;

    &:hover {
      background-color: $text-orange-active;
    }
  }

  &.cta-primary a {
    background-color: $text-orange;
    color: $text-white;

    &:hover {
      background-color: $text-orange-active;
    }

    &.disabled {
      background-color: $text-disabled;
      pointer-events: none;
    }
  }

  &.cta-secondary-1 a,
  &.cta-secondary-2 a {
    background-color: $text-white;
    border-color: $text-orange;
    color: $text-orange;

    &:hover {
      background-color: $text-orange;
      color: $text-white;
    }

    &.disabled {
      border-color: $text-disabled;
      color: $text-disabled;
      pointer-events: none;
    }
  }

  &.cta-secondary-1 a::before {
    @include fa-icon;

    content: $fa-var-plus;
    margin-right: 0.375rem;
  }

  &.cta-secondary-2 a {
    padding: 0.5rem 0.875rem;
  }
}

.btn-cta-primary {
  background-color: $text-orange;
  border: 2px solid transparent;
  border-radius: $border-radius;
  color: $text-white;
  display: inline-block;
  font-weight: 600;
  line-height: 1.43;
  padding: $default-inputs-padding;
  text-decoration: none;

  &:hover {
    background-color: $text-orange-active;
  }

  &.disabled {
    background-color: $text-disabled;
    pointer-events: none;
  }
}

.btn-cta-secondary-1,
.btn-cta-secondary-2 {
  @extend %text-sans-serif;

  background-color: $text-white;
  border: 2px solid $text-orange;
  border-radius: $border-radius;
  color: $text-orange;
  font-weight: 600;
  padding: $default-inputs-padding;
  text-decoration: none;

  &:hover {
    background-color: $text-orange-active;
    color: $text-white;
  }
}

.btn-cta-secondary-1::before {
  @include fa-icon;

  content: $fa-var-plus;
  margin-right: 0.375rem;
}
