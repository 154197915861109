@import "abstracts/vars";
@import "abstracts/mixins";

.promo {
  background: $promo-bg;
  box-sizing: border-box;
  overflow: hidden;
  padding: 15px;

  >.component-content {
    position: relative;

    @include clearfix;
    @include border-basic(all, $promo-border);

    >div {
      padding: 5px;
    }
  }

  .zg-height-fix {
    .field-promoicon {
      margin-top: 100px;
    }
  }

  .field-promoicon {
    overflow: hidden;
    width: 100%;

    img {
      height: auto;
      width: 100%;
    }
  }

  .field-promolink {
    margin-top: 5px;
    padding-bottom: 10px;
  }

  &.image-full-size {
    img {
      margin-bottom: 10px;
    }
  }

  @import "variants/promo/default";
  @import "base/richtext/richtext";
}

@import "styles/promo/absolute-bottom-link";
@import "styles/promo/promo-hero";
@import "styles/promo/promo-shadow";
