@import "abstracts/vars";
@import "abstracts/mixins";

.promo.promo-hero {
  position: relative;
  text-align: center;

  &.promo-hero-half {
    float: left;
    width: 50%;

    @include respond-to(all-mobile) {
      float: none;
      width: 100%;
    }
  }

  .field-promotext {
    background: $promo-bg-hero;
    color: $promo-hero-text-color;
    display: inline-block;
    left: 0;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    >a {
      color: inherit;
      text-decoration: none;
    }

    @include respond-to(all-mobile) {
      margin: 0;
      width: 100%;

      h1 {
        @include font-size(2.4);
      }

      h2,
      h3,
      h4 {
        @include font-size(2);
      }
    }
  }
}
