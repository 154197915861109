@import "abstracts/mixins";
@import "abstracts/vars";
@import "libs/fontawesome/font-awesome-mix-var";

.sxa-archive {
  .level-1 {
    display: block !important;
    padding-left: 10px;

    & > li > .group-header {
      font-weight: bold;
    }
  }

  .group-header {
    position: relative;

    &::before {
      @include font-size(1);
      @include fa-icon;

      content: $fa-var-plus-square;
      left: -15px;
      line-height: 10px;
      padding: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &.opened {
      &::before {
        content: $fa-var-minus-square;
      }
    }
  }

  ul {
    cursor: pointer;
    display: none;
    margin-left: 10px;

    li {
      position: relative;
    }
  }

  .archive-items {
    display: none;

    .archive-item {
      @import "base/reset/links";

      cursor: default;
    }
  }

  // TODO:
  // @import "./variants/archive/default.scss";
}
