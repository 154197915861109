@import "abstracts/vars";
@import "abstracts/mixins";
@import "libs/fontawesome/font-awesome-mix-var";

.toggle {
  details[open] summary::before {
    content: $fa-var-minus-square;
  }

  summary.toggle-header {
    &::marker {
      display: none;
    }

    &::before {
      @include fa-icon;

      content: $fa-var-plus-square;
      font-size: 17px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    background: $toggle-header-bg;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    min-height: 25px;
    padding: 0 10px;
    position: relative;
    vertical-align: middle;

    &.show {
      display: inline-block !important;
    }

    .toggle-label {
      display: block;
      float: right;
      margin: 0 0 0 10px;

      p {
        margin-bottom: 0;
      }
    }
  }

  .show {
    color: $toggle-show-color;
  }

  .toggle-content {
    margin-top: -1px;
  }

  details summary ~ .component {
    >.component-content {
      animation-delay: 0;
      animation-direction: normal;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      animation-timing-function: linear;
      display: none;
    }
  }

  details[open] summary ~ .component {
    overflow: hidden;

    >.component-content {
      display: block;
      transform: translateY(-100%);
    }
  }

  @import "base/animation/toggle";
}
