/* stylelint-disable scss/at-extend-no-missing-placeholder */
@import "variables";
@import "libs/bootstrap-custom/bootstrap";


form[id^="fxb_"] {
  @extend .form-group;

  label[for^="fxb_"] {
    display: block;

    &:last-child {
      content: unset;
    }
  }

  label[for^="fxb_"] + input,
  label[for^="fxb_"] + textarea,
  label[for^="fxb_"] + select {
    @extend .form-control;

    background-color: $gray-50;
    border: $gray-200;
    border-radius: 2px !important;
    font-size: 1rem;
    height: auto;
    line-height: 1.2;
    padding: 1rem;
    width: 100%;
  }

  label > input[type="checkbox"],
  label > input[type="radio"] {
    margin-right: 0.25rem;
  }

  input[type="submit"] {
    @extend #{".btn",".btn-primary", ".btn-lg"};

    display: block;
    margin-top: 1rem;
  }


  .field-validation-valid {
    @extend .valid-feedback;

    display: inline-block;
    margin-bottom: 1rem;
  }

  .field-validation-error {
    @extend .invalid-feedback;

    display: inline-block;
    margin-bottom: 1rem;
  }
}

