@import "abstracts/vars";
@import "abstracts/mixins";

.title {
  background: $title-bg;

  .field-title a {
    @include border-basic(bottom, $border-basic-color);

    color: $title-color;
    cursor: pointer;
    display: block;
    font-size: $font-extrabig;
    line-height: normal;
    margin-bottom: $small-margin;
    padding-bottom: 10px;
    text-decoration: none;

    &:hover {
      color: $title-color-active;
    }
  }

  @import "variants/title/default";
}
