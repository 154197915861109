@import "./abstracts/vars";
@import "./abstracts/mixins";
@import "libs/fontawesome/font-awesome-mix-var";

.overlay {
  background: url("#{$static-assets-path}/images/overlay-bg.png");
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9000;

  &.component {
    margin: 0;
  }

  >.component-content {
    background: $overlay-bg;
    background-color: #E6E6E6;
    border-radius: 5px;
    display: block;
    left: 50%;
    max-width: 90%;
    padding: 10px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    .component {
      margin: 0;

      &.image {
        img {
          width: 100%;
        }
      }
    }
  }

  .overlay-inner {
    height: inherit;
    max-height: inherit;
    min-width: 25px;
    overflow: auto;

    &.overlay-percent {
      height: auto;
      max-height: 100%;
    }

    #InnerCenter {
      height: auto;
      width: auto;
    }

    .container {
      width: 100%;
    }

    >img {
      width: 100%;
    }
  }

  .overlay-inner:focus {
    border: 1px solid #cccccc;
  }

  .overlay-close {
    cursor: pointer;
    font-size: 30px;
    position: absolute;
    right: -10px;
    top: -20px;

    &::before {
      background: #FFFFFF;
      border-radius: 50%;
      content: $fa-var-times-circle-o;

      @include fa-icon;
    }

    &:hover {
      &::before {
        background: #cccccc;
      }
    }

    a {
      display: block;
      text-indent: -9999px;
    }
  }

  .overlay-close-link:focus {
    color: #cccccc;
  }

  .overlay-close-link:hover {
    color: #cccccc;
  }

  .overlay-close-link {
    text-decoration: none;
  }
}

/* this css class is used on overlay page */

.overlay-page {
  background: url("#{$static-assets-path}/images/overlay-bg.png");
  bottom: 0;
  left: 0;
  margin: 0;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 9999;

  >.component-content {
    background: $overlay-bg;
    background-color: #E6E6E6;
    border-radius: 5px;
    display: inline-block;
    *display: inline;
    margin: 100px 60px 60px;
    max-width: 90%;
    padding: 10px 10px 25px;
    position: relative;
    vertical-align: middle;
    zoom: 1;

    .component {
      margin: 0;

      &.image {
        img {
          width: 100%;
        }
      }
    }
  }

  .overlay-inner {
    height: inherit;
    max-height: inherit;
    min-height: 135px;
    min-width: 25px;
    overflow: auto;

    &.overlay-percent {
      height: auto;
      max-height: 100%;

      &.edit {
        max-height: inherit;
      }
    }

    .container {
      width: 100%;
    }
  }

  .overlay-inner:focus {
    border: 1px solid #cccccc;
  }

  .overlay-close {
    bottom: 8px;
    bottom: -15px;
    color: #000;
    cursor: pointer;
    font-size: 30px;
    position: absolute;
    right: -5px;
  }

  .overlay-close-link:focus {
    color: #cccccc;
  }

  .overlay-close-link:hover {
    color: #cccccc;
  }

  .overlay-close-link {
    text-decoration: none;
  }
}

#wrapper {
  overflow: hidden;

  &.overlay-page {
    margin-bottom: -80px;
  }
}

body.preview {
  .overlay-page {
    position: fixed;
  }
}

.overlay-page .main {
  width: 100%;
}

.on-page-editor {
  .overlay {
    .overlay-inner {
      margin: auto;
    }
  }

  .overlay-page {
    z-index: 9000;
  }
}
