.facet-summary,
.facet-summary-horizontal {
  .removeFacetValue {
    color: $text-blue;
    cursor: pointer;
    font-size: 1.35rem;
    font-weight: normal;
    padding-left: 0.5rem;
    padding-right: 0.25rem;
  }

  .bottom-remove-filter {
    button {
      cursor: pointer;
      font-size: 12px;
      font-weight: bold;
      position: relative;
    }
  }

  .clear-filter {
    display: none;
  }
}

.facet-summary:not(.facet-summary-horizontal) {
  margin-bottom: 10px;

  .component-content {
    .facet-summary-wrapper {
      background-color: $search-facet-summary-background;
      padding-top: 10px;

      .active-facet-summary-element {
        float: left;
        font-size: 12px;
        padding-top: 10px;
        position: relative;
        width: 100%;

        span {
          font-weight: bold;
        }

        .facet-summary-name {
          font-weight: bold;
          margin-right: 0.6rem;
        }

        .facet-summary-value {
          align-items: center;
          border-bottom: 1px solid $search-facet-summary-item-border;
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.25rem;
          padding: 0.1rem 0.8rem;
          padding-left: 0;
          word-break: break-word;
        }
      }
    }

    .bottom-remove-filter {
      float: left;
      padding-top: 10px;
      width: 100%;

      button {
        border-color: transparent;
        color: $search-facet-summary-clear-color;
        padding-left: 3px;
        text-align: left;
        text-indent: 10px;
        width: 100%;

        &::before {
          color: $search-facet-summary-clear-color;
          content: "x";
          display: block;
          left: 1px;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }
}

.facet-summary-horizontal {
  .facet-summary-wrapper {
    margin: 10px 0;
  }

  .facet-summary-value,
  .facet-summary-name {
    float: left;
    font-size: 1.3rem;
    margin-bottom: 0.9rem;
  }

  .facet-summary-name {
    padding: 2px 0.85rem 2px 0;
  }

  .facet-summary-value {
    border-radius: 12px;
    box-shadow: 0 2px 6px 0 $search-facet-summary-item-shadow;
    margin-right: 0.95rem;
    padding: 2px 18px 2px 8px;
    position: relative;
    word-break: break-word;
  }

  .removeFacetValue {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
  }

  .facet-summary-wrapper.clearfix::after,
  .facet-summary-wrapper.clearfix::before {
    display: none;
  }

  .bottom-remove-filter {
    float: left;

    button {
      background: transparent;
      border: 1px solid $search-facet-summary-clear-border-horizontal;
      border-radius: 12px !important;
      color: $search-facet-summary-item-color-horizontal;
      padding: 0.2rem 0.9rem;
      width: auto;
    }
  }
}

.facet-summary-horizontal.facet-summary-horizontal-break {
  .active-facet-summary-element {
    @include clearfix;
  }
}
