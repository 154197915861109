span.field-title {
  &.tag-links {
    border-radius: 20px;
    display: block;
    float: left;
    margin-right: $default-padding;
    margin-top: $default-padding;
    padding: 5px $default-padding;
    position: relative;

    >a {
      padding-left: 20px;
      position: relative;
      text-decoration: none;

      &::before {
        @include fa-icon;

        color: #fff;
        content: $fa-var-circle;
        display: block;
        font-size: 6px;
        left: 10px;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
