/* stylelint-disable max-nesting-depth */
@import "abstracts/vars";
@import "abstracts/mixins";

.search-results {
  position: relative;

  .search-result-list {
    padding-top: 5px;
  }

  &.vertical,
  &.component-details,
  &.standard-fields {
    .search-result-list {
      > li {
        // @include border-basic(all, $border-width: 1px 1px 2px 1px);

        box-sizing: border-box;
        display: inline-block;
        margin: 0 7px 10px 0;
        padding: 5px;
        position: relative;
        vertical-align: top;
        width: 100%;

        .field-title {
          display: block;
          margin-top: 10px;

          a {
            color: $gray-900;
            display: block;
            font-size: $font-medium;
            font-weight: normal;
            line-height: 20px;
            text-decoration: none;

            &:hover {
              color: $gray-900;
            }
          }
        }

        a {
          img {
            height: auto;
            max-width: 100%;
          }
        }

        &:hover {
          @include border-basic(bottom, $search-item-border-active, 1px 1px 2px 1px);
        }
      }
    }
  }

  &.horizontal,
  &.components,
  &.example-fields {
    .search-result-list {
      @include clearfix;

      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;

      li {
        box-sizing: border-box;
        flex: 1;
        margin: 0 2% 10px 0;
        min-height: 120px;
        min-height: 175px;
        min-width: 23%;
        padding: 5px;
        position: relative;

        // @include border-basic(all, $border-width: 1px 1px 2px 1px);

        .field-title {
          display: block;
          padding: 0 5px;
          text-align: center;

          a {
            font-size: 18px;
            font-weight: normal;
            line-height: 20px;
            text-decoration: none;
          }
        }

        a {
          img {
            height: auto;
            max-width: 100%;
          }
        }

        &:hover {
          @include border-basic(bottom, $search-item-border-active, 1px 1px 2px 1px);
        }
      }
    }
  }

  .search-results-overlay {
    display: none;
  }

  &.loading-in-progress {
    min-height: 200px;

    .search-result-overlay {
      // background-color: rgba(255, 255, 255, 90%);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      &::after {
        background-image: url("data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSJibGFjayI+CiAgPHBhdGggIG9wYWNpdHk9Ii4yNSIgZD0iTTE2IDAgQTE2IDE2IDAgMCAwIDE2IDMyIEExNiAxNiAwIDAgMCAxNiAwIE0xNiA0IEExMiAxMiAwIDAgMSAxNiAyOCBBMTIgMTIgMCAwIDEgMTYgNCIvPgogIDxwYXRoIGZpbGw9JyNkZGRkZGQnIGQ9Ik0xNiAwIEExNiAxNiAwIDAgMSAzMiAxNiBMMjggMTYgQTEyIDEyIDAgMCAwIDE2IDR6Ij4KICAgIDxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBmcm9tPSIwIDE2IDE2IiB0bz0iMzYwIDE2IDE2IiBkdXI9IjAuOHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPgogIDwvcGF0aD4KPC9zdmc+");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        content: "";
        height: 50px;
        left: 0;
        margin: -30px auto 0;
        position: absolute;
        right: 0;
        top: 50%;
        width: 50px;
      }
    }
  }

  &.loading-more-in-progress {
    &::after {
      background-size: 50% 50%;
      content: "";
      display: block;
      height: 100px;

      @include loading-gif;
    }
  }

  .no-results {
    padding: 5px;
  }
}
