@import "abstracts/vars";
@import "abstracts/mixins";

.rich-text-lists,
.field-featurelist {
  ul {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    li {
      @include font-size(1.3);

      line-height: 21px;
      list-style: none;
      margin: 0;
      padding: 5px 0 5px 25px;
      position: relative;

      &::before {
        color: $text-basic;
        content: "\f00c";
        display: inline-block;
        font-family: FontAwesome, sans-serif;
        left: 0;
        margin: 0 5px 0 0;
        padding: 0;
        position: absolute;
        text-align: center;
        top: 3px;
        vertical-align: middle;
        width: 20px;

        @include font-size(1.2);
      }
    }
  }
}

.field-featurelist {
  ul {
    li {
      &::before {
        color: $text-basic;
        content: "\f046";
        display: inline-block;
        font-family: FontAwesome, sans-serif;
        left: 0;
        margin: 0 5px 0 0;
        padding: 0;
        position: absolute;
        text-align: center;
        top: 3px;
        vertical-align: middle;
        width: 20px;

        @include font-size(1.2);
      }
    }
  }
}

.media-link {
  &.file-type-icon-media-link {
    float: left;

    .field-filetypeicon {
      text-align: center;
    }
  }
}
