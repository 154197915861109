/* stylelint-disable no-duplicate-selectors */
@import "abstracts/vars";
@import "abstracts/mixins";

.tabs.tabs-bottom {
  .tabs-container {
    margin: 0;
  }

  .tabs-heading {
    margin-top: -1px;
    position: relative;

    li {
      border-top: 0 solid $border-white;
      margin-bottom: 0;

      a {
        border: none;
      }

      &.active {
        border-top: none;

        @include border-basic(bottom, $tab-container-border, 2px);
        @include border-basic(left);
        @include border-basic(right);

        a {
          border: none;
          position: relative;
        }
      }

      &:hover {
        @include border-basic(bottom, $tab-container-border, 2px);

        background: $tab-heading-active-bg;
      }
    }
  }

  .tabs-container {
    @include border-basic(bottom, $tab-container-border);

    background: $tab-container-bg;
    border-top: none;
    margin-bottom: 1px;
  }
}
