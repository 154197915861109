@import "abstracts/mixins";

&.sector-navigation {
  background-color: transparent !important;
  height: 50px;

  &.list-vertical li {
    margin: 0;
  }

  > .component-content {
    display: flex;
    height: 100%;
    width: 100%;

    /**
   * The 'Toggle' is rendered in a h3 tag by SXA
   */
    > h3,
    > .toggle-button {
      align-items: center;
      background: transparent !important;
      border: none;
      color: var(--theme-color) !important;
      cursor: pointer;
      display: inline-flex;
      flex-direction: row;
      font-family: Lora, serif;
      font-size: 0.875rem;
      font-weight: bold;
      line-height: inherit;
      margin: 0 1rem 0 0;
      padding: 0;
      position: relative;
      width: auto !important;

      &::before {
        @include fa-icon;

        content: $fa-var-chevron-down;
        font-size: 0.875rem;
        line-height: 0.5;
        margin-right: 0.875rem;
      }
    }

    > h3 + ul,
    > .toggle-button + ul {
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 15%);
      border-radius: 2px;
      display: none;
      list-style: none;
      margin: 0.125rem 0 0;
      margin-top: -0.5rem;
      min-width: 10rem;
      padding: 0;
      top: 100%;

      &.expanded {
        display: block;
        left: 0;
        position: absolute;
        width: fit-content;
        z-index: 100;
      }

      li a {
        border-bottom: 1px solid transparent;
        display: inline-block;
        padding: 0.5rem;
        width: 100%;

        &:hover {
          background-color: var(--theme-color);
          color: $text-white;
        }
      }

      li a::before {
        content: unset;
        display: none;
      }
    }
  }
}
