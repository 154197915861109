@import "abstracts/mixins";
@import "abstracts/vars";

.flip {
  background: $flip-bg;
  perspective: 1000;

  h2 {
    margin-bottom: 5px;
  }

  .Side1,
  .Side0,
  .Side0_EDIT,
  .Side1_EDIT {
    background: $flip-slides-bg;
    padding: 2%;
    width: 96%;

    @include clearfix;
  }

  .Side0_EDIT,
  .Side1_EDIT {
  }

  .Side1_EDIT {
    margin-top: 5px;
  }

  .flipsides {
    position: relative;
    word-wrap: break-word;
  }

  .Side1,
  .Side0 {
    @include border-basic;

    backface-visibility: hidden;
    box-sizing: border-box;
    cursor: pointer;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transform-style: preserve-3d;
    transition-duration: 1s;
    transition-property: transform;
    transition-timing-function: ease-in;
    width: 100%;

    .slide-heading,
    .slide-content {
      @include clearfix;
    }
  }

  .Side1 {
    animation: slide0hide 1s ease-in 1s forwards;
    transform: rotateY(180deg);
  }

  &.active {
    .Side0 {
      animation: slide0hide 1s ease-in 1s forwards;
      transform: rotateY(180deg);
    }

    .Side1 {
      animation: slide1show 0s ease-in 1s forwards;
      transform: rotateY(360deg);
    }
  }
}

@import "styles/flip/flip-fade";
@import "styles/flip/flip-slide-top";
@import "styles/flip/flip-vertical";

.no-csstransforms3d {
  .flip {
    .Side1 {
      display: none;
    }

    &.active {
      .Side0 {
        display: none;
      }

      .Side1 {
        display: block;
      }
    }
  }
}

.on-page-editor {
  .flipsides {
    height: auto;

    .Side0,
    .Side1 {
      margin-bottom: 10px;
    }
  }
}

@keyframes slide0hide {
  to {
    visibility: hidden;
  }
}

@keyframes slide1show {
  to {
    visibility: visible;
  }
}
