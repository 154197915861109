.search-results-count {
  .progress {
    display: none;
  }

  .results-count {
    display: none;
  }
}

.on-page-editor {
  .results-count {
    display: inline-block;
  }
}
