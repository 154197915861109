@import "abstracts/vars";
@import "abstracts/mixins";

.link-list.usps > .component-content {
  ul {
    align-items: flex-start;
    background: transparent;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    list-style: none;
    padding: 0;
  }

  li {
    padding: 0 0.5rem;

    &:first-child {
      padding-left: 0;
    }
  }

  a {
    color: $text-basic;
    font-family: $font-family-sans-serif;
    font-size: 0.75rem;
    text-decoration: none;
  }

  a[href=""],
  a[href=""]:hover,
  a[href=""]:active,
  a[href=""]:focus {
    cursor: text;
    outline: none;
    text-decoration: none;
  }

  .field-link .fa::before {
    color: var(--theme-color);
    padding-right: 0.25rem;
  }
}
