.sort-results {
  @import "../reset/inputs";

  select {
    display: inline-block;
    max-width: 100%;
    width: auto;
  }

  .facet-title {
    display: inline-block;
    margin-right: 5px;
  }
}
