&.file-list-files {
  > .component-content {
    display: flex;
    flex-wrap: wrap;
  }

  .file-description {
    align-items: center;
    display: inline-flex;
    margin-left: 0;
    width: 84%;

    .field-title {
      display: inline-block;
      font-size: $font-normal;
      font-weight: 600;
      vertical-align: middle;
    }

    .field-filetypeicon {
      height: 24px;
      order: 1;
      padding-right: 10px;
      text-align: center;
      vertical-align: middle;
      width: 6%;

      span {
        &::before {
          font-size: 24px !important;
        }
      }
    }

    .info-wrapper {
      order: 1;
      width: 93%;

      .field-size,
      .field-updated {
        display: inline-block;
        font-size: 12px;
      }
    }
  }

  .field-linktext {
    border-radius: 3px;
    display: inline-flex;
    justify-content: center;
    padding: 10px 13px;
    text-align: center;
    width: 15%;

    a {
      line-height: 22px;
    }
  }

  @media only screen and (max-width: 768px) {
    .file-description {
      flex-wrap: wrap;
      width: 100%;

      .field-filetypeicon {
        width: 100%;

        span {
          margin: 0 auto;
        }
      }

      .info-wrapper {
        padding: $default-padding 0;
        text-align: center;
        width: 100%;
      }
    }

    .field-linktext {
      margin: 0 auto;
      width: 100%;
    }
  }
}
