@import "abstracts/mixins";
@import "abstracts/vars";

.field-editor {
  background: $field-editor-bg;
  overflow: hidden;

  .field-table {
    table {
      table-layout: fixed;
      word-wrap: break-word;
    }

    border-collapse: collapse;
    width: 100%;

    tbody {
      background: none;
      border: none;

      tr {
        background: none;
      }
    }

    th {
      color: $field-editor-text-header;
      padding: 5px 10px;
      text-align: left;
      vertical-align: middle;
    }

    td {
      color: $field-editor-text;
      padding: 5px 10px;
      text-align: left;
      vertical-align: middle;
    }
  }

  @import "variants/field-editor/default";
}

.field-editor.position-right {
  .field-table {
    td {
      text-align: right;
    }
  }
}

.field-editor.position-center {
  .field-table {
    td {
      text-align: center;
    }
  }
}
