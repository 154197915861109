@import "abstracts/vars";
@import "abstracts/mixins";

.link-list.list-vertical {
  h3 {
    background: $bg-basic-color;
    display: inline-block;
    padding: 3px 5px;
    width: 100%;

    @include border-basic;
  }

  a {
    border: none;
  }

  li {
    display: block;
    margin-left: $middle-margin;
  }
}
