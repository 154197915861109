@import "abstracts/vars";
@import "abstracts/mixins";
@import "libs/fontawesome/font-awesome-mix-var";

.loupe-search-btn {
  .search-box-button,
  .search-box-button-with-redirect {
    background: #000000 !important;
    border: none;
    padding: 4px 14px;
    position: relative;
    text-indent: -99999px;
    width: 20px;

    &:hover {
      background: #89c6cc !important;
    }

    &::before {
      @include fa-icon;

      color: #fff;
      content: $fa-var-search;
      font-size: 10px;
      font-size: 14px;
      left: 50%;
      position: absolute;
      text-indent: 0;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
