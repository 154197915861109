@import "abstracts/vars";
@import "variables";

.page-selector {
  padding: 10px 0 0;
  text-align: center;

  .page-selector-list {
    li {
      display: inline-block;
      margin: 0 8px;
      vertical-align: middle;

      &.inactive {
        a {
          color: $gray-200;
          cursor: default;
        }
      }
    }

    .page-selector-item-previous,
    .page-selector-item-next {
      .fa {
        align-items: center;
        border: 2px solid $primary;
        border-radius: 2px;
        color: $primary;
        display: flex;
        height: 40px;
        justify-content: center;
        width: 40px;

        &::before {
          font-size: 17px;
          line-height: 36px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    .page-selector-item-previous.inactive,
    .page-selector-item-next.inactive {
      .fa {
        border-color: $gray-200;
        color: $gray-200;
      }
    }
  }

  .page-selector-item-link {
    color: $gray-900;
    display: inline-block;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 1rem;
    line-height: 1.75;
    text-decoration: none;
    width: 0.5rem;

    &.active {
      color: $primary;
    }

    &:hover {
      color: $primary;
      text-decoration: underline;
    }


    &.inactive {
      color: $gray-200;
    }
  }
}
