@import "abstracts/vars";
@import "abstracts/mixins";
@import "libs/fontawesome/font-awesome-mix-var";

.breadcrumb.triangle-separator {
  .separator {
    @include fa-icon;

    font-size: 0;

    &::before {
      content: $fa-var-caret-right;
      display: block;
      font-size: $font-medium;
    }
  }
}
