@import "variables";

.divider {  
  height: 1px;
  width: 100%;
}

.divider hr {
	border: 0;
	border-bottom: 1px solid $gray-100;
	height: 0;
}