@import "abstracts/vars";
@import "abstracts/mixins";

.location-filter {
  margin: 0 0 20px;
  padding: 5px 10px 15px;
  position: relative;

  @import "../reset/inputs";
  @import "../typehead/typehead";

  .component-content {
    display: flex;

    >div,
    >button,
    >label,
    >input,
    >span {
      order: 1;
    }

    >label,
    >button {
      flex-grow: 1;
    }

    >label {
      align-self: center;
      text-align: center;
    }

    >input,
    >span {
      flex-grow: 10;
      margin: 0 5px;
      width: auto;
    }
  }

  .tt-hint::input-placeholder {
    color: transparent;
  }

  .tt-hint::placeholder {
    color: transparent;
  }
}
