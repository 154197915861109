.facet-range-selector {
  .slider-min-value {
    float: left;
    text-align: center;
    width: 4%;
  }

  .slider-max-value {
    float: right;
    text-align: center;
    width: 4%;
  }

  .slider-min-value + .ui-slider-horizontal {
    float: left;
    margin: 5px 2%;
    width: 88%;
  }
}
