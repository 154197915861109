/* stylelint-disable max-nesting-depth */
.carousel.slide-hero {
  >.component-content {
    .carousel-inner {
      .wrapper {
        padding-bottom: 0;
        .background {}

        .slides {
          li.slide {
            .component.content {
              padding-left: 0;
              padding-right: 0;

              .component-content {
                .field-slideimage {
                  min-height: 200px;
                  img {}
                }

                .slide-info {
                  background-color: transparent;
                  left: 50px;
                  position: absolute;
                  top: 50%;
                  transform: translate(0, -50%);
                  z-index: 999;
                }

                .filed-slidelink {
                  a {}
                }
              }
            }
          }
        }

        .nav {
          height: 0;
          margin-top: 0;

          .prev-text,
          .next-text {
            bottom: auto;
            top: 50%;
            transform: translateY(-50%);
          }
          .prev-text {}

          .nav-items {
            bottom: 15px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);

            .sxa-numbers {
              &.cative {}
            }

            .sxa-bullets {
              &.active {}
            }
          }
          .next-text {}
        }
      }
    }
  }
}
