@import "abstracts/vars";
@import "abstracts/mixins";

.content,
.rich-text {
  background: $rich-content-bg;
  border: $rich-content-border;

  * {
    max-width: 100%;
  }

  @import "base/richtext/richtext-files-icons";
  @import "base/richtext/richtext";
}

.rich-text {
  @import "variants/rich-text/default";

  width: 100%;
}

.content {
  @import "variants/page-content/default";
}

@import "styles/rich-text/rich-text-lists";
