@import "./abstracts/vars";
@import "./abstracts/mixins";
@import "./abstracts/functions";

body {
  &.on-page-editor {
    @include opensans-font-stack;

    background: $page-bg-editor !important;
    color: $text-basic;
    font-size: 14px;
    line-height: 1.5;
    overflow: auto;
    position: relative;
  }
}

.xa-variable {
  background-color: #ebebe4;
  border: 0;
  color: #545454;
  margin: 0;
  padding: 1px;
  pointer-events: none;
  user-select: none;
}

// Navigation Bar fix
#breadcrumbMenuSubcontrol_context_menu * {
  box-sizing: initial;
}

// End navigation bar fix
