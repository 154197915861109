/* stylelint-disable no-duplicate-selectors, max-nesting-depth */
@import "abstracts/vars";
@import "abstracts/mixins";
@import "libs/fontawesome/font-awesome-mix-var";

.playlist {
  background-color: $play-list-bg;
  padding: 20px 10px 0;

  a:hover {
    text-decoration: none;
  }

  .component-content {
    >h1,
    >h2,
    >h3,
    >h4,
    >h5,
    >h6 {
      margin: 0 0 10px;

      @include border-basic(bottom, $play-list-title-border);
    }
  }

  .playlist-item {
    margin: 0 0 10px;
    overflow: hidden;
    padding: 0;

    .playlist-section {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-flow: row wrap;

      >.scEmptyPlaceholder {
        flex-basis: 100%;
      }

      >div {
        order: 1;
      }

      .field-moviecaption {
        a,
        a:hover {
          text-decoration: none !important;
        }
      }

      .field-moviethumbnail {
        max-width: 150px;
        padding: 10px 10px 8px 5px;

        a {
          display: block;
          text-align: center;

          img {
            height: auto;
            width: 100%;
          }
        }
      }

      .field-moviecaption {
        font-size: $font-normal;

        >a {
          color: $play-list-item-color;
          display: block;
          font-size: 1em;
          padding: 15px 10px;
        }
      }
    }

    &.active {
      background-color: $play-list-item-active-bg;

      .field-moviecaption a {
        color: $play-list-item-color-active;
        font-weight: bold;
      }
    }
  }

  .playlist-nav {
    padding: 5px;
    text-align: center;

    a {
      display: block;
      height: 100%;
      text-indent: -9999px;
    }

    .playlist-prev,
    .playlist-next {
      display: inline-block;
      height: 17px;
      vertical-align: middle;
      width: 14px;
    }

    .playlist-next a,
    .playlist-prev a {
      position: relative;

      &::before {
        @include fa-icon;

        content: $fa-var-chevron-left;
        left: 0;
        position: absolute;
        text-indent: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &:hover {
        color: $play-list-nav-active;
      }
    }

    .playlist-prev a {
      &::before {
        content: $fa-var-chevron-left;
      }
    }

    .playlist-next a {
      &::before {
        content: $fa-var-chevron-right;
      }
    }
  }

  @import "variants/play-list/default";
}

.video-playlist {
  &.show {
    display: block;
  }
}

.on-page-editor {
  .video-playlist {
    display: block;
  }
}

@import "styles/play-list/playlist-horizontal";
