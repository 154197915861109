@import "abstracts/mixins";
@import "abstracts/vars";
@import "libs/fontawesome/font-awesome-mix-var";

.on-page-editor {
  .accordion .toggle-content [scfieldtype="rich text"] {
    display: block;
  }
}

.accordion {
  margin-bottom: 10px;
  padding: 0;

  .active {
    >.scEnabledChrome {
      >.toggle-header {
        .field-heading::before {
          content: $fa-var-minus-square !important;
        }
      }
    }

    >.toggle-header {
      .field-heading::before {
        content: $fa-var-minus-square !important;
      }
    }
  }

  .toggle-header {
    background-color: $accordion-header-bg;
    cursor: pointer;

    @include border-basic(bottom);

    &:hover {
      background-color: $accordion-header-bg-active;
    }

    .component {
      margin-top: 0;

      &.title {
        margin-top: 0;
      }

      .field-heading {
        font-size: $accordion-header;
        font-weight: normal;
        padding-left: 34px;

        &::before {
          @include fa-icon;

          content: $fa-var-plus-square;
          display: block;
          font-size: 20px;
          height: 20px;
          left: 10px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 20px;
        }
      }
    }

    .label {
      @include clearfix;

      background: transparent;
      display: block;
      padding: 7px;
      position: relative;
      text-align: left;
      z-index: 3;
    }
  }

  .toggle-content {
    background-color: $accordion-toggled-bg;
    padding: 10px;

    @include clearfix;

    .component {
      margin-top: 0;
    }
  }

  .items {
    overflow: hidden;
  }

  .item {
    &:last-child {
      border-bottom: none;
    }
  }
}

// todo
// @import "./styles/accordion/accordion-horizontal.scss";
