@import "abstracts/vars";
@import "abstracts/mixins";
@import "utilities/typography";

.facet-blogcategory,
.facet-sector {
  .facet-search-filter {
    .facet-value {
      border-bottom: none;
      display: inline;
    }
  }

  .bottom-remove-filter {
    display: none;
  }
}


.facet-single-selection-list {
  .facet-hided {
    .hide-facet-value {
      display: none;
    }
  }

  .toogle-facet-visibility {
  }

  .facet-dropdown {
    .facet-value {

    }
  }
}
