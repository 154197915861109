@import "abstracts/mixins";
@import "abstracts/vars";
@import "libs/fontawesome/font-awesome-mix-var";

%tag-style {
  border-bottom: 1px solid transparent;
  color: $tag-color;
  margin-right: 10px;
  padding-left: 15px;
  position: relative;

  &:hover {
    border-bottom: 1px solid $tag-border-active;
    color: $tag-color-active;
    cursor: pointer;
    text-decoration: none;
  }

  &::before {
    content: $fa-var-tag;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include fa-icon;
  }
}

.tag-list {
  span.field-title {
    &.tag-text {
      @extend %tag-style;
    }

    >a {
      background: $tag-link-bg;
      border-radius: 3px 0 0 3px;
      color: $tag-color;
      color: $tag-link-color;
      display: inline-block;
      height: 26px;
      line-height: 26px;
      margin: 0 20px 10px 0;
      padding: 0 15px 0 23px;
      position: relative;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        background-color: $tag-link-bg-active;

        &::after {
          border-left: 10px solid $tag-link-bg-active;
        }
      }

      &::before {
        background: #fff;
        border-radius: 10px;
        box-shadow: inset 0 1px rgba(0, 0, 0, 25%);
        content: "";
        height: 6px;
        left: 10px;
        position: absolute;
        top: 10px;
        width: 6px;
      }

      &::after {
        background: transparent;
        border-bottom: 13px solid transparent;
        border-left: 10px solid $tag-link-bg;
        border-top: 13px solid transparent;
        bottom: 0;
        content: "";
        position: absolute;
        right: -10px;
        top: 0;
      }
    }
  }

  @import "variants/tag-list/default";
  @import "variants/tag-list/Links";
  @import "variants/tag-list/Texts";
}
