@import "abstracts/vars";
@import "abstracts/mixins";

.title-row-box {
  @include border-basic;

  background: $container-title-row-bg;
  box-sizing: border-box;
  height: 87px;
  margin: 0;
  padding: 0;
  width: 100%;

  >.component-content {
    @include wrapper;

    .title {
      box-sizing: border-box;
      display: inline-block;
      margin: 23px 0 0;
      padding: 0;
      vertical-align: middle;

      h1 {
        @include font-size(2.2);

        border: none;
      }

      @include respond-to(all-mobile) {
        margin: 23px 0 0 20px;
      }
    }

    .rich-text {
      margin: 0;

      h1,
      h2,
      h3,
      h4 {
        margin: 0;
        padding: 0;
      }
    }

    .breadcrumb {
      box-sizing: border-box;
      clear: both;
      display: inline-block;
      float: right;
      margin: 23px 0 0;
      padding: 0;
      vertical-align: middle;

      @include font-size(1.4);

      @include respond-to(tablet) {
        margin: 23px 20px 0 0;
      }

      @include respond-to(mobile) {
        display: none;
      }
    }
  }

  @include respond-to(all-mobile) {
    .alpha {
      box-sizing: border-box;
      float: left;
      vertical-align: middle;
      width: auto !important; //overrides grid
    }

    .omega {
      box-sizing: border-box;
      float: right;
      vertical-align: middle;
      width: auto !important; //overrides grid
    }
  }
}
