@import "abstracts/mixins";
@import "abstracts/vars";
@import "base/sprites/sprite-flag";
@import "libs/fontawesome/font-awesome-mix-var";

.language-selector {
  display: grid;
  height: 50px;
  margin-bottom: unset;
  place-items: center;
  position: relative;

  @include sprites($spritesheet-sprites);

  .language-selector-select-item {
    cursor: pointer;

    a {
      color: var(--theme-color-2);
      display: block;
      font-family: $font-family-serif;
      font-size: 0.875rem;
      font-weight: 600;
      padding-left: 24px;
      position: relative;
      text-transform: uppercase;
      width: 80px;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &::before {
    @include fa-icon;

    color: var(--theme-color-2);
    content: $fa-var-angle-down;
    font-size: 1rem;
    left: 8px;
    position: absolute;
  }

  &.is-active::before {
    content: $fa-var-angle-up;
  }
}

.language-selector-item-container {
  background: $bg-basic-color;
  border-radius: $border-radius;
  box-shadow: $box-shadow-color;
  display: none;
  left: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 110%;
  width: 87px;
  z-index: 10;
}

.language-selector-item {
  background-color: $white;
  border-bottom: 1px solid $gray-200;
  cursor: pointer;
  display: grid;
  height: 2rem;
  padding: 0.5rem;
  place-items: center;

  &:last-child {
    border-bottom: none;
  }

  a {
    background-repeat: no-repeat;
    display: block;
    font-family: $font-family-serif;
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;

    &:hover {
      text-decoration: none;
    }
  }

  &.is-active a {
    color: var(--theme-color-2);
  }
}

.language-selector-link {

}

.language-selector-select-link {
  background-repeat: no-repeat;
}
