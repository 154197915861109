/* stylelint-disable no-descending-specificity */
@import "abstracts/vars";
@import "abstracts/mixins";
@import "../utilities/typography";

.sitecore-form {
  // @import "base/reset/inputs";

  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    appearance: none; // Fix appearance for date inputs in Safari
  }

  .has-feedback {
    @include clearfix;
  }

  .scfRequired {
    display: none !important;
  }

  .form-control {
    @extend %text-sans-serif;

    background-clip: padding-box;
    background-color: $gray-100;
    border: 1px solid $gray-200;
    border-radius: $border-radius;
    color: $text-basic;
    display: block;
    padding: 0.5rem;
  }

  > .component-content {
    border: 1px solid transparent;
    color: $text-basic;

    > .row {
      // For some reason we have an extra <div class="row"> breaking the grid layout
      margin: 0;
      padding: 0;
    }
  }

  form {
    color: $form-color;
    font-size: $font-normal;

    h3.newsletter-heading {
      margin-bottom: 0;
    }

    > div {
      padding: 15px 0;

      label {
        font-family: inherit;
        padding-right: $default-padding;
      }

      input {
        font-family: inherit;
      }

      label + textarea {
        margin-top: $default-padding;
      }

      textarea,
      select {
        margin-top: $default-padding;
      }
    }

    [data-sc-field-name="Password Confirmation"] {
      margin-right: $default-padding;
    }
  }

  // TODO:
  // @import "./styles/forms/hints-below.scss";
  @import "styles/forms/labels-above";
}
