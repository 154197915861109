.facet-dropdown {
  margin: 10px 0;

  select {
    background-color: $bg-basic-color;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 7.5%);
    box-sizing: border-box;
    display: inline-block;
    font-size: 14px;
    height: 34px;
    margin-bottom: 5px;
    min-width: 100%;
    padding: 6px 12px;
    position: relative;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    vertical-align: middle;

    @include border-basic;
  }
}
