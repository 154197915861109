@import "abstracts/vars";
@import "abstracts/mixins";
@import "libs/fontawesome/font-awesome-mix-var";

.component.video {
  .mejs-container {
    overflow: hidden;
  }

  .mejs-controls {
    .mejs-button {
      button {
        box-shadow: none;
        &:hover {}
      }
    }
  }

  .sxa-video-wrapper {
    position: relative;
  }

  .video-init {
    // background: url("/-/media/Base-Themes/Core-Libraries/styles/mejs-controls.svg") no-repeat;
    background-position: 0 -39px;
    height: 80px;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    width: 80px;
    z-index: 1;
  }
}
