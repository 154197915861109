@import "abstracts/vars";
@import "abstracts/mixins";

.tabs.tabs-vertical {
  .tabs-heading {
    float: left;
    width: 30%;

    li {
      display: block;
      margin: 0 0 2px;
      width: 100%;

      @include border-basic(top, $tab-heading-bg);
      @include border-basic(left, $tab-heading-bg, 2px);
      @include border-basic(right, $tab-container-border);
      @include border-basic(bottom, $tab-heading-bg);

      &.active {
        @include border-basic(left, $tab-container-border, 2px);

        a {
          border: none;
        }
      }

      &:first-child {
        margin-top: 0;
      }

      &:hover {
        @include border-basic(left, $tab-container-border, 2px);
      }
    }
  }

  .tabs-container {
    border-top: none;
    float: left;
    margin: 0;
    margin-left: -1px;
    width: 70%;

    @include border-basic(left, $tab-container-border, 1px);
  }
}
