@import "abstracts/vars";

.image {
  img {
    height: auto;
    max-width: 100%;
  }

  a {
    display: inline-block;
    max-width: 100%;
  }

  .image-caption {
    color: $image-caption-color;
    display: block;
    font-size: $font-small;
    font-style: italic;
    margin-bottom: $middle-margin;
  }

  //@ import "variants/image/*";
}

//@ import "styles/image/*";
@import "styles/image/image-default-size";

//@ import "styles/image-alignment/*";
@import "styles/image-alignment/image-left";
@import "styles/image-alignment/image-right";
