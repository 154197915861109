@import "abstracts/vars";
@import "abstracts/mixins";

header .component.search-box.horizontal {
  align-items: center;
  display: inline-grid;

  .twitter-typeahead {
    align-items: stretch;
    display: flex;
    position: relative;
    width: 100%;

    .search-box-input {
      align-items: center;
      display: block;
      height: 100%;
      width: 100%;

      &.tt-input {
        background-color: #fff;
        border: 1px solid var(--theme-color);
        border-radius: 0.25rem !important;
        font-size: 1rem;
        padding: 0.75rem;

        &:focus,
        &:focus-visible {
          box-shadow: 0 0 0 2px var(--theme-color) inset;
          outline: none;
        }
      }
    }
  }

  &.loupe-search-btn {
    position: relative;

    &::before {
      @include fa-icon;

      color: var(--theme-color);
      content: $fa-var-search;
      font-size: 1.2rem;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-2rem, -50%);
      z-index: 10;
    }
  }
}

.IE11 header .component.search-box.horizontal.loupe-search-btn::before {
  transform: translate(-2rem, -200%);
}
