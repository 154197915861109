.ui-slider {
  margin: 10px auto;
  position: relative;
  text-align: left;
  width: 80%;

  .ui-widget-header {
    background: $search-filter-slider-bg-active;
    border: 1px solid $search-filter-slider-border-active;
    font-weight: bold;
  }

  .ui-widget-header a {
    color: #ffffff;
  }
}

.slider-value {
  text-align: center;
}

/* Interaction states
---------------------------------- */

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: $search-filter-slider-btn-bg;
  border: 1px solid $search-filter-slider-btn-border;
  color: $search-item-color;
  font-weight: normal;
}

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
  color: $search-item-color;
  text-decoration: none;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  background: $search-filter-slider-btn-bg-active;
  border: 1px solid $search-filter-slider-btn-border;
  color: $search-item-color-active;
  font-weight: normal;
}

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited {
  color: $search-item-color-active;
  text-decoration: none;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  background: $search-filter-slider-btn-bg-active;
  border: 1px solid $search-filter-slider-btn-border;
  color: $search-item-color-active;
  font-weight: normal;
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: $search-item-color-active;
  text-decoration: none;
}

.ui-slider .ui-slider-handle {
  cursor: default;
  height: 1.2em;
  position: absolute;
  touch-action: none;
  width: 1.2em;
  z-index: 2;
}

.ui-slider .ui-slider-range {
  background-position: 0 0;
  border: 0;
  display: block;
  font-size: 0.7em;
  position: absolute;
  z-index: 1;
}

/* For IE8 - See #6727 */

.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
  filter: inherit;
}

.ui-slider-horizontal {
  height: 0.8em;
}

.ui-slider-horizontal .ui-slider-handle {
  margin-left: -0.6em;
  top: -0.3em;
}

.ui-slider-horizontal .ui-slider-range {
  height: 100%;
  top: 0;
}

.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.ui-slider-vertical {
  height: 100px;
  width: 0.8em;
}

.ui-slider-vertical .ui-slider-handle {
  left: -0.3em;
  margin-bottom: -0.6em;
  margin-left: 0;
}

.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
  top: 0;
}
