h1,
h2,
h3,
h4,
h5,
h6 {
  color: $text-heading-color;
  font-weight: bold;
}

h1 {
  font-family: Lora, serif;
  font-size: $font-extrabig;
  letter-spacing: 1px;
  line-height: 1.4167;
}

h2 {
  font-size: $font-big;
  line-height: 1.75;
  margin-bottom: $extrasmall-margin;
  margin-top: $large-margin;
}

h3 {
  font-size: $font-medium;
  margin: 0;
}

h4,
h5,
h6 {
  margin: 0;
}

p {
  line-height: 1.4286;
  margin: 0;
  margin-bottom: $middle-margin;
}

strong {
  font-weight: 700;
}

ul,
ol {
  margin-left: $middle-margin;
  padding-bottom: $small-margin;
  padding-top: $small-margin;
}

li {
  font-size: $font-normal;
}

ul {
  li {
    list-style: none;

    &::marker,
    &::before {
      color: $text-green;
      font-weight: bold;
    }

    &::before {
      content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      display: inline-block;
      height: 0.5em;
      margin-left: -$small-margin;
      margin-top: -20px;
      vertical-align: middle;
      width: $small-margin;
    }
  }
}

ol {
  li {
    list-style: decimal;
    list-style-position: inside;
  }
}

a {
  color: $rich-content-link-color;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: $rich-content-link-color-active;
  }
}

table {
  border: none;
  height: auto !important;

  &,
  tr,
  th,
  td {
    border: none;
    border-collapse: collapse;
    vertical-align: middle;
  }

  tr,
  th,
  td {
    padding: $small-margin $extrasmall-margin;
  }

  caption {
    background-color: $bg-light-gray;
    font-size: $font-big;
    font-weight: bold;
    margin-left: 0;
    overflow: hidden;
    padding: 10px;
  }

  tbody tr {
    border-top: 1px solid rgba(0, 0, 0, 12%);

    &:nth-child(even) {
      background-color: $bg-light-gray;
    }
  }

  th {
    background-color: $bg-green;
    color: $text-white;
    font-weight: bold;
  }

  td {
    &:last-child {
      border-top: none;
    }
  }
}

hr {
  border-top: 1px solid $border-gray;
}
