/* stylelint-disable no-duplicate-at-import-rules */
@import "abstracts/vars";
@import "abstracts/mixins";

.event-calendar {
  @import "base/richtext/richtext-files-icons";
  @import "base/richtext/richtext";

  background: $calendar-bg;
  border-bottom: none !important;
  box-sizing: border-box;
  overflow: hidden;
  padding: 5px;

  .fc-day-grid-event {
    &:hover {
      color: $calendar-day-color-active;
    }
  }

  .fc-content-skeleton {
    td {
      padding: 0 !important;
    }
  }

  .fc-bg > table {
    height: 100% !important;
  }

  .fc-day-header {
    background: $calendar-header-bg;
  }

  th,
  td {
    border-width: 1px 0 0 1px !important;
  }

  table {
    border: 1px solid $border-gray;

    &,
    tr,
    th,
    td {
      background: transparent;
    }
  }

  .fc-event-container {
    a {
      color: $calendar-day-color-active;
      text-decoration: none;
    }
  }
}

.calendar-tooltip {
  @import "base/richtext/richtext-files-icons";
  @import "base/richtext/richtext";
}
