@mixin default-link-button {
  @include border-basic;
  @include font-size(1.2);

  box-sizing: border-box;
  display: inline-block;
  font-weight: bold;
  margin: 5px 0;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;

  &:hover {
    background: $bg-light-gray;
    color: $text-basic;
  }
}

.button-default,
.default {
  @include default-link-button;

  background: $gray-100;
  color: $text-basic;
}

.button-success,
.success {
  @include default-link-button;

  background: $btn-green-light;
  color: $text-white;

  &:hover {
    background: $btn-green;
    color: $text-white;
  }
}

.button-warning,
.warning {
  @include default-link-button;

  background: $btn-red;
  color: $text-white;

  &:hover {
    background: $btn-red-active;
    color: $text-white;
  }
}
