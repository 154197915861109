@import "abstracts/vars";
@import "abstracts/mixins";

.market-selector {
  @import "base/reset/inputs";

  select {
    color: $site-selector-color;
    font-size: $font-normal;
    height: auto;
    padding: 5px 15px 5px 5px;
  }
}
