/* stylelint-disable max-nesting-depth */
@import "abstracts/mixins";
@import "abstracts/vars";
@import "libs/fontawesome/font-awesome-mix-var";

.breadcrumb.component {
  background: $breadcrumb-bg;

  @include clearfix;

  &.breadcrumb-navigation {
    overflow: visible;
  }

  a {
    color: $breadcrumb-color;
    font-size: $breadcrumb-font;
    font-weight: 400;
    text-decoration: none;
  }

  ol {
    list-style: none;
  }

  .field-title {
    display: inline-block;
  }

  .separator {
    display: inline-block;
    margin: 0 5px;
    vertical-align: middle;
  }

  li {
    display: block;
    float: left;
    position: relative;

    >.navigation-title {
      display: inline-block;
      vertical-align: middle;

      >a:hover {
        color: $breadcrumb-color-active;
      }
    }

    &.last {
      .separator {
        display: none;
      }
    }

    &.item-hide {
      &:hover {
        >ol {
          display: none;
        }
      }
    }

    &:hover {
      >ol {
        display: block;
        left: auto;
        position: absolute;
        top: 20px;
        z-index: 100;
      }
    }

    >ol {
      background: $bg-basic-color;
      box-sizing: border-box;
      display: none;
      margin: 0;

      @include border-basic;

      li {
        @include border-basic(bottom);

        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        float: none;
        margin: 0;
        min-width: 140px;
        padding: 2px 20px;
        position: relative;
        vertical-align: middle;
        width: 100%;

        .fa {
          float: left;
        }

        &:hover {
          background: $breadcrumb-dropdown-bg-active;
          color: $breadcrumb-dropdown-text-active;

          a {
            color: $breadcrumb-dropdown-text-active !important;
          }
        }

        &::before {
          @include font-size(1.4);
          @include fa-icon;

          content: $fa-var-globe;
          left: 2px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        >a {
          display: block;
          padding: 2px 0 2px 10px;
        }

        &:last-child {
          border-bottom-width: 0;
        }
      }
    }
  }

  // TODO:
  // @import "./variants/breadcrumb/default.scss";
}

// TODO:
// @import "styles/breadcrumb/breadcrumb-hide";
@import "styles/breadcrumb/triangle-separator";
