/* Default Bootstrap */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
// @import "bootstrap/scss/root";

@import "./reboot";

/*
// @import "bootstrap/scss/reboot";
// @import "bootstrap/scss/type";
// @import"bootstrap/scss/code";
*/

@import "bootstrap/scss/images";
@import "bootstrap/scss/grid";

@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";

/*
// @import"bootstrap/scss/card";
// @import"bootstrap/scss/breadcrumb";
// @import"bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import"bootstrap/scss/jumbotron";
*/

@import "bootstrap/scss/alert";

// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/media";
// @import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
// @import "bootstrap/scss/modal";
@import "bootstrap/scss/transitions";
// @import "bootstrap/tscss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";

@import "bootstrap/scss/utilities";

/*
// @import"bootstrap/scss/print";
*/

// Component Overrides
@import "./badge";
@import "./buttons";
@import "./custom-forms";
@import "./modal";
@import "./utilities/grid";

@import "./tooltip";

.debugmarker {
  color: "tomato";
}
