.column-header-search {
  .search-box {
    margin: 0;
    padding: 2px 10px 0;
  }
}

.search-box {
  @import "../reset/inputs";
  @import "../typehead/typehead";

  box-sizing: border-box;
  padding-right: 10px;
  position: relative;

  &.hide-button {
    .search-box-button {
      display: none;
    }
  }

  .component-content {
    display: flex;

    >label,
    >input,
    >button {
      display: inline-block;
    }

    >label,
    >button {
      flex-grow: 1;
    }

    >input {
      flex-grow: 10;
      margin: 0 5px;
      width: auto;
    }

    >label {
      align-self: center;
      text-align: center;
    }

    >.twitter-typeahead {
      flex-grow: 10;
      margin: 0 5px;

      .search-box-input.tt-input {
        margin-bottom: 0;
      }
    }
  }

  .search-box-input {
    align-self: center;
    position: relative;

    &.tt-hint {
      margin-top: 0;
    }
  }
}
