@import "abstracts/vars";
@import "abstracts/mixins";

.twitter-typeahead {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
  width: 100%;
}

.tt-menu {
  background: $bg-basic-color;
  border: 1px solid $border-gray;
  width: 100%;
}

.tt-hint {
  color: $tt-color;
}

.tt-dropdown-menu {
  background-color: $tt-dropdown-bg;
  border: 1px solid $tt-dropdown-border;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 20%), 0 0 0 1px rgba(0, 0, 0, 8%);
  margin-top: 5px;
  max-height: 300px;
  overflow-y: auto;
  width: 250px;
}

.tt-suggestion {
  color: $tt-color;
  overflow: hidden;
  padding: 4px 10px;

  a {
    text-decoration: none;
  }

  &:last-child {
    border: none;
  }

  .field-image {
    float: left;
    margin-right: 10px;
    width: 100px;
  }

  .field-make {
    float: left;
    font-size: 15px;
    margin-right: 5px;
  }

  .field-model {
    font-size: 15px;
    font-weight: bold;
    margin-left: 4px;
    width: 100%;
  }

  .field-priceformatted {
    color: $tt-price-color;
    float: right;
    font-size: 15px;
    margin-top: -20px;
  }
}

.tt-suggestion.tt-cursor {
  background-color: $tt-suggestion-bg-active;
  color: $tt-color-active;
  cursor: pointer;
}

&.loading-in-progress {
  .tt-dropdown-menu {
    display: block !important;
    min-height: 50px;
    position: relative;

    &::after {
      content: "";
      display: block;
      height: 40px;
      left: 50%;
      margin-left: -20px;
      margin-top: -20px;
      position: absolute;
      top: 50%;
      width: 50px;
      z-index: 11;

      @include loading-gif;
    }

    &::before {
      background-color: $tt-dropdown-bg;
      content: "";
      height: 100%;
      left: 0;
      opacity: 80%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 10;
    }
  }
}
