@import "abstracts/vars";
@import "abstracts/mixins";

.media-link {
  background: $media-link-bg;

  @include border-basic(bottom,$media-link-border);

  a {
    color: $media-link-color;

    &:hover {
      color: $media-link-color-active;
      text-decoration: none;
    }
  }

  .component-content {
    .scEmptyPlaceholder {
      margin-left: 0;
      width: 100%;
    }

    >* {
      display: inline-block;
      margin-left: 5px;
      vertical-align: middle;
    }
  }

  &.position-right {
    .field-filetypeicon,
    .field-linktext {
      float: right;
    }
  }

  .field-filetypeicon,
  .field-linktext {
    display: inline-block;
  }

  .field-filetypeicon {
    > a {
      display: block;
      height: 24px !important;
    }

    span {
      display: block;
      height: 24px !important;

      &::before {
        font-size: 18px;
      }
    }
  }

  .field-linktext {
    a {
      font-size: 1em;
    }
  }

  @import "variants/media-link/default";
  @import "variants/media-link/icon-name-date-and-download-link";
  @import "base/richtext/richtext-files-icons";
}

@import "styles/media-link/media-link-horizontal";
