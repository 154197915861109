@import "abstracts/mixins";
@import "abstracts/vars";
@import "libs/fontawesome/font-awesome-mix-var";

%eventWrapper {
  @include clearfix;

  align-items: center;
  background: $event-list-item-bg;
  color: $event-list-item-color;
  display: inline-flex;
  flex-flow: row wrap;
  width: 100%;
}

.event-list {
  @import "base/richtext/richtext-files-icons";
  @import "base/richtext/richtext";

  background: $event-list-bg;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include border-basic(bottom, $event-list-title-border);
  }

  ul {
    margin: $small-margin 0;
  }

  li {
    @include border-basic;
    @extend %eventWrapper;

    box-sizing: border-box;
    margin: $small-margin 0;
    margin-left: 0;
    padding: 15px 15px 40px;
    position: relative;

    > .scLooseFrameZone {
      @extend %eventWrapper;

      > .scEmptyPlaceholder {
        flex-basis: 100%;
      }
    }
  }

  .event-list-time {
    .scWebEditInput {
      display: inline;
    }

    flex-grow: 1;
    font-weight: bold;
    max-width: 20%;
    min-width: 115px;
    order: 1;
  }

  .event-list-info {
    flex-grow: 2;
    margin: 0 15px;
    max-width: 75%;
    order: 1;
  }

  .event-list-name {
    font-size: 16px;
    font-weight: bold;
  }

  .event-list-place,
  .event-list-link {
    bottom: 5px;
    padding-left: 15px;
    position: absolute;

    &::before {
      @include fa-icon;

      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .event-list-place {
    left: 10px;

    &::before {
      content: $fa-var-map-marker;
    }
  }

  .event-list-link {
    right: 10px;

    a {
      color: $link-text-color;
      font-size: 1em;

      &:hover {
        border-bottom: 1px solid $link-border;
        color: $link-text-color-active;
        text-decoration: none;
      }
    }

    &::before {
      content: $fa-var-calendar-o;
    }
  }

  .scWebEditInput {
    @include opensans-font-stack;
  }
}
