@import "abstracts/mixins";
@import "abstracts/vars";

a {
  background-color: transparent;
  border-bottom: 1px solid $border-basic-color;
  color: $text-basic;
  font-size: 1em;
  text-decoration: none;

  &:hover {
    color: $text-basic-active;
    text-decoration: none;
  }
}
