&.service-navigation {
  >.component-content {
    h3 {
      margin: 0 1rem 0 0;
    }

    ul {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 50px;
      justify-content: flex-end;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li .scEnabledChrome > .field-link {
      display: inline;
    }

    li {
      margin: 0;
      padding: 0;

      ~ li {
        padding-left: 1rem;
      }

      // stylelint-disable no-descending-specificity
      &,
      & > div {
        display: inline;
      }

      a {
        color: rgba(0, 0, 0, 54%);
        display: inline-block;
        font-size: $font-small;
        line-height: 1.5em;
        padding: $default-inputs-padding 0;

        &::before {
          display: none;
        }

        &:hover {
          border-bottom: none;
          color: var(--theme-color);
        }
      }
    }
  }
}
