@import "abstracts/mixins";
@import "abstracts/vars";

.tabs {
  background: none;
  border: none;
  overflow: hidden;
  position: relative;
}

.tab-slider {
  background: $tab-heading-bg;
  border: 2px solid $border-basic-color;
  cursor: pointer;
  position: absolute;
  text-indent: -9999px;
  vertical-align: center;
  width: 15px;
  z-index: 10;
}

.tabs-heading {
  overflow: hidden;

  li {
    @include border-basic(top, $tab-heading-bg, 2px);
    @include border-basic(left, $tab-heading-bg);
    @include border-basic(right, $tab-heading-bg);
    @include border-basic(bottom, $tab-container-border);

    background: $tab-heading-bg;
    cursor: pointer;
    display: block;
    float: left;
    margin-left: 1px;
    width: auto;

    >div {
      @include clearfix;

      color: $tab-heading-color;
      display: block;
      padding: 3px 10px;
      text-decoration: none;

      .field-heading {
        font-size: $tab-font-header;
      }

      >.row {
        display: block;
      }
    }

    .component.content {
      margin: 5px 0;
    }

    &.active {
      background: $tab-heading-active-bg;

      @include border-basic(top, $tab-container-border, 2px);
      @include border-basic(bottom, $tab-container-border);
      @include border-basic(left);
      @include border-basic(right);

      a {
        color: $tab-heading-active-color;
      }
    }

    &:hover {
      @include border-basic(top, $tab-container-border, 2px);

      background: $tab-heading-active-bg;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

.tabs-container {
  @include border-basic(top, $tab-container-border);

  background: $tab-container-bg;
  margin-top: -1px;
}

.tab {
  @include clearfix;

  display: none;
  padding: 10px;

  &.active {
    display: block;
  }
}

@import "styles/tabs/tabs-bottom";
@import "styles/tabs/tabs-vertical-right";
@import "styles/tabs/tabs-vertical";
@import "styles/tabs-overflow/tabs-scrollable";
