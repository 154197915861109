@import "abstracts/mixins";
@import "abstracts/vars";

label {
  background: transparent;
  color: $text-basic;
}

input,
button {
  background: $bg-basic-color;
  border: 1px solid $border-gray;
  box-sizing: border-box;
  clear: both;
  color: $text-basic;
  display: inline-block;
  font-size: $font-normal;
  font-weight: normal;
  height: auto;
  padding: 8px 2%;
}

input:focus {
  border: 1px solid $border-gray;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  border: 1px solid $border-basic-color;
  padding: 5px 20px;

  &:hover {
    background: $bg-light-gray;
  }
}

input[type="text"] {
  font-size: 13px;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea,
select {
  background: #fff;
  border: 2px solid $border-gray;
  color: $text-basic;
  width: 100%;
}

select {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  outline: none;
  padding: 5px;
  vertical-align: middle;
}
