.facet-managed-range {
  box-sizing: border-box;

  .min-range-value,
  .max-range-value {
    display: inline-block;
    float: left;
    margin-bottom: 5px;
    vertical-align: middle;
    width: 49%;

    input[type="text"] {
      min-width: 100%;
      width: 100%;
    }
  }

  .min-range-value {
    float: left;
  }

  .max-range-value {
    float: right;
  }

  .bottom-remove-filter {
    float: left;
  }
}
