@import "abstracts/vars";
@import "abstracts/mixins";

.twitter {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-bottom: 6px;

    @include border-basic(bottom);
  }

  .twitter-follow-button {
    margin: 10px 0 0;
  }

  .tweet-item {
    @include border-basic(top, $border-basic-color);

    margin-left: 10px;
    overflow: hidden;
    padding: 10px 10px 10px 0;
    padding-left: 55px;
    position: relative;

    &:first-child {
      background: none;
      border: none;
    }
  }

  .tweet-header {
    overflow: hidden;
  }

  .tweet-profile {
    display: block;
    float: left;
    font-size: 14px;
    text-decoration: none;
  }

  .tweet-avatar {
    left: 0;
    position: absolute;
    top: 10px;
  }

  .tweet-datetime {
    display: block;
    float: right;
    text-decoration: none;
  }

  .tweet-content {
    margin-top: 5px;
  }

  .tweet-footer {
    >ul li {
      display: inline;
      padding-left: 5px;

      &:first-child {
        padding: 0;
      }
    }

    a {
      text-decoration: none;
    }
  }
}
