@import "abstracts/vars";
@import "abstracts/mixins";

.map {
  background: $map-bg;

  //@include border-basic(all, $map-border);
  overflow: hidden;
  position: relative;

  .map-canvas {
    position: relative;
  }

  img {
    max-width: none;
  }

  .MicrosoftMap .infobox-close {
    float: right;
    position: static;
  }

  .MicrosoftMap .Infobox .infobox-body {
    height: 100%;
  }

  .MicrosoftMap .Infobox .infobox-info {
    box-sizing: border-box;
    height: 100%;
  }

  .field-title {
    font-weight: bold;
  }

  @import "variants/map/default";
}
