@import "abstracts/mixins";
@import "abstracts/vars";

.skip-link {
  background: #bf1722;
  border-bottom-right-radius: 8px;
  color: #fff !important;
  left: 0;
  padding: 8px;
  position: absolute;
  top: -40px;
  z-index: 100;

  &:focus {
    top: 0;
  }
}
