&.social-navigation {
  ul {
    display: flex;
    justify-content: flex-start;
  }

  li {
    margin-right: 1rem;
  }

  .field-link a {
    font-size: 0 !important;
    line-height: 0;
    width: auto;

    &::before {
      align-items: center;
      background-color: var(--theme-color-2);
      border-radius: 100%;
      color: #FFF;
      display: flex;
      font-size: 1rem !important;
      height: 40px;
      justify-content: center;
      width: 40px;
    }

    &:hover {
      text-decoration: none !important;

      &::before {
        background-color: var(--theme-color-3);
      }
    }
  }
}
