@import "abstracts/vars";
@import "abstracts/mixins";

.promo-shadow {
  border-style: solid;
  border-top-color: $promo-shadow-border;
  border-top-width: 3px;
  max-width: 960px;
  overflow: visible;
  padding: 0;
  position: relative;

  &.promo {
    float: left;
  }

  >.component-content {
    @include respond-to(all-mobile) {
      margin: 0 10px 30px;
    }

    margin: 0 0 30px;
    padding: 15px;

    &::before,
    &::after {
      bottom: 10px;
      box-shadow: 0 17px 10px rgba(0, 0, 0, 70%);
      content: "";
      height: 20%;
      max-height: 100px;
      max-width: 460px;
      opacity: 70%;
      position: absolute;
      width: 47%;
      z-index: -1;
    }

    &::before {
      left: 2%;
      transform: rotate(-3deg);
    }

    &::after {
      right: 2%;
      transform: rotate(3deg);
    }
  }
}
